import {
  Box,
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import { baseURL } from '../../utils';

const Message3b = ({ show, onClose, readExpertRequestMessage }) => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={show}
      // isCentered
      motionPreset="slideInBottom"
      size="2xl"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Congratulations, {authState?.user?.name}!</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderRadius={20}
          paddingX={10}
          paddingTop={2}
          paddingBottom={10}
        >
          <VStack spacing={8} alignItems="flex-start">
            <Text fontSize={'xl'}>
              Our team has reviewed your application and we're pleased to bring
              you on as an expert on NewForge Knowledge Place!
            </Text>
            <Text fontSize={'xl'}>When you first login as an expert:</Text>
            <UnorderedList fontSize={'xl'}>
              <ListItem fontWeight={'bold'}>Double-check your profile</ListItem>
              <Text>
                Your profile as an expert has been pre-filled with the
                information from your application. We suggest double-checking to
                make sure elements like your hourly rate and working hours are
                still accurate.
              </Text>
              <ListItem mt={4}>
                <Text fontWeight={'bold'}>
                  Review the expert walkthrough{' '}
                  <Link
                    color={'purple.500'}
                    onClick={() => {
                      readExpertRequestMessage();
                    }}
                    href={`${baseURL}/onboard/expert-walkthrough`}
                    isExternal
                  >
                    Here
                  </Link>
                </Text>
              </ListItem>
              <Text>
                Check out our expert walkthrough to learn about new features you
                have access to as an expert.
              </Text>
            </UnorderedList>

            <Text fontSize={'xl'}>
              <strong>If you have any questions: please refer to our</strong>{' '}
              <Link
                color={'purple.500'}
                href={`${baseURL}/onboard`}
                isExternal
              >
                Training Library
              </Link>{' '}
              or contact us at{' '}
              <Link
                color={'purple.500'}
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                fontWeight="bold"
                isExternal
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Link>
            </Text>
            <Box>
              <Text fontSize={'xl'} fontWeight="bold">
                We'd love to hear your feedback as we continue to improve our
                platform. Check out our feedback form on the bottom of your
                screen or click{' '}
                <Link
                  color="purple.500"
                  href="https://forms.gle/V4YH573oQeyzjf8K7"
                  target={'_blank'}
                  fontWeight="bold"
                >
                  Here
                </Link>{' '}
                to leave your feedback 😊
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Message3b;
