import { TourNavbarWrapper } from '../../components/toursWrappers/TourNavbarWrapper';
import { TourDashboardWrapper } from '../../components/toursWrappers/TourDashboardWrapper';
import { TourEnterpriseDashboardWrapper } from '../../components/toursWrappers/TourEnterpriseDashboardWrapper';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../AuthProvider';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FULL_MODAL_NOTIFICATION_VALUES } from '../../redux/slices/notificationSlice';
import { useGetNotificationsQuery } from '../../redux/api/notificationApi';

// tour names
const NAVBAR_TOUR_COMPLETED = 'navbarTourCompleted';
const DASHBOARD_TOUR_COMPLETED = 'dashboardTourCompleted';
const ENTERPRISE_DASHBOARD_TOUR_COMPLETED = 'enterpriseDashboardTourCompleted';
const PROFILE_TOUR_COMPLETED = 'profileTourCompleted';

export const TOURS = {
  NAVBAR_TOUR_COMPLETED,
  DASHBOARD_TOUR_COMPLETED,
  ENTERPRISE_DASHBOARD_TOUR_COMPLETED,
  PROFILE_TOUR_COMPLETED,
};

const TourContext = createContext({});
/**
 * For managing tours in a possible future where we need to mix them all.
 * I know we can use something simpler and on each page add the corresponding tour but just in case...
 * @param {*}
 * @returns
 */
export const ToursContext = ({ children }) => {
  const tourRef = useRef();
  const { authState, isEnterprise, isUnlimitedEnterprise } =
    useContext(AuthContext);
  const { isLoading: isNotificationLoading } = useGetNotificationsQuery(null, {
    pollingInterval: 10000,
    refetchOnMountOrArgChange: true,
  });
  const notificationState = useSelector(store => store?.notifications);
  // add loading validation to prevent flickering. If existsFullModalNotifications hide tour
  const existsFullModalNotifications =
    isNotificationLoading ||
    FULL_MODAL_NOTIFICATION_VALUES.some(v => notificationState[v]) ||
    notificationState?.incomingCallNotification?.length > 0;
  const [currentTour, setCurrentTour] = useState('');
  const location = useLocation();
  // console.log('currentTour', currentTour);

  const [dashboardTourCompleted, setDashboardTourCompleted] = useState(
    localStorage.getItem(TOURS.DASHBOARD_TOUR_COMPLETED) === 'true'
  );
  const [
    enterpriseDashboardTourCompleted,
    setEnterpriseDashboardTourCompleted,
  ] = useState(
    localStorage.getItem(TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED) === 'true'
  );
  const [profileTourCompleted, setProfileTourCompleted] = useState(
    localStorage.getItem(TOURS.PROFILE_TOUR_COMPLETED) === 'true'
  );
  const [navbarTourCompleted, setNavbarTourCompleted] = useState(
    localStorage.getItem(TOURS.NAVBAR_TOUR_COMPLETED) === 'true'
  );

  useEffect(() => {
    if (existsFullModalNotifications) return setCurrentTour();
    getNextTour(); // initialize
  }, [
    existsFullModalNotifications,
    dashboardTourCompleted,
    enterpriseDashboardTourCompleted,
    profileTourCompleted,
    navbarTourCompleted,
    location.pathname,
  ]);

  const isInPageToDisplayTour = tour => {
    // not logged no tour
    if (!authState) return false;
    // if (tour === TOURS.NAVBAR_TOUR_COMPLETED) {
    //   return true;
    // }
    if (tour === TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED) {
      return location.pathname === '/admin/dashboard';
    }
    if (tour === TOURS.DASHBOARD_TOUR_COMPLETED) {
      return location.pathname === '/dashboard';
    }
    if (tour === TOURS.PROFILE_TOUR_COMPLETED) {
      return location.pathname === '/profile';
    }
  };

  // console.log('dashboardTourCompleted', dashboardTourCompleted);
  // console.log('navbarTourCompleted', navbarTourCompleted);

  const getNextTour = () => {
    // if (
    //   !navbarTourCompleted &&
    //   isInPageToDisplayTour(TOURS.NAVBAR_TOUR_COMPLETED)
    // ) {
    //   setCurrentTour(TOURS.NAVBAR_TOUR_COMPLETED);
    // } else
    if (
      !profileTourCompleted &&
      isInPageToDisplayTour(TOURS.PROFILE_TOUR_COMPLETED)
    ) {
      setCurrentTour(TOURS.PROFILE_TOUR_COMPLETED);
    } else if (
      !enterpriseDashboardTourCompleted &&
      (isEnterprise || isUnlimitedEnterprise) &&
      isInPageToDisplayTour(TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED)
    ) {
      setCurrentTour(TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED);
    } else if (
      !dashboardTourCompleted &&
      // !isEnterprise &&
      // !isUnlimitedEnterprise &&
      isInPageToDisplayTour(TOURS.DASHBOARD_TOUR_COMPLETED)
    ) {
      setCurrentTour(TOURS.DASHBOARD_TOUR_COMPLETED);
    } else {
      setCurrentTour();
    }
  };

  const handleOnTourFinished = () => {
    // console.log('handleOnTourFinished');
    localStorage.setItem(currentTour, 'true');
    if (currentTour === DASHBOARD_TOUR_COMPLETED) {
      setDashboardTourCompleted(true);
    }
    if (currentTour === ENTERPRISE_DASHBOARD_TOUR_COMPLETED) {
      setEnterpriseDashboardTourCompleted(true);
    }
    if (currentTour === PROFILE_TOUR_COMPLETED) {
      setProfileTourCompleted(true);
    }
    if (currentTour === NAVBAR_TOUR_COMPLETED) {
      setNavbarTourCompleted(true);
    }
    getNextTour();
  };

  /**
   * This will go to next tour step if implemented...
   */
  const handleGoNext = () => {
    // TODO: implement it for the other tour pages if needed. Also go back if needed too.
    if (currentTour === TOURS.DASHBOARD_TOUR_COMPLETED) {
      tourRef?.current?.goToNext();
    }
    // if (currentTour === TOURS.PROFILE_TOUR_COMPLETED) {

    // }
    // if (currentTour === TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED) {

    // }
  };

  return (
    <TourContext.Provider
      value={{
        currentTour,
        setCurrentTour,
        handleOnTourFinished,
        handleGoNext,
      }}
    >
      <TourDashboardWrapper ref={tourRef}>
        <TourEnterpriseDashboardWrapper>
          <TourNavbarWrapper>{children}</TourNavbarWrapper>
        </TourEnterpriseDashboardWrapper>
      </TourDashboardWrapper>
    </TourContext.Provider>
  );
};

export const useTours = () => React.useContext(TourContext);
