import { Spinner, Box } from '@chakra-ui/react';

const Loader = () => (
  <Box
    backgroundColor="rgba(255,255,255,0.90)"
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
    display="flex"
    justifyContent="center"
    alignItems="center"
    zIndex={999}
  >
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="orange.500"
      size="xl"
    />
  </Box>
);

export default Loader;
