import { Container } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider';
import Navbar from './Navbar';
import Footer from './Footer';

const ProtectedAdminLayout = ({ history }) => {
  const { isAuthenticated, isSessionLoading, authState } =
    useContext(AuthContext);
  const location = useLocation();
  // console.log('authState', authState);
  // console.log('isAuthenticated', isAuthenticated);
  // console.log('isSessionLoading', isSessionLoading);
  // console.log(
  //   'isAuthenticated, isSessionLoading',
  //   isAuthenticated,
  //   isSessionLoading
  // );
  if (!isAuthenticated && !isSessionLoading) return <Navigate to="/login" />;

  // only sysadmins can access here
  if (authState?.user && !['sysadmin'].find(t => authState?.user?.type.includes(t)))
    return <Navigate to="/forbidden" />;

  if (
    authState?.user?.validated &&
    !isSessionLoading &&
    location.pathname.includes('validate-email')
  )
    return <Navigate to="/dashboard" />;

  // force choose plan
  if (
    !location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    !authState?.user?.subscription &&
    !isSessionLoading &&
    !authState?.user?.parent // add this for the case when we have admin enterprise users. These users will have a parent and enterprise type.
  )
    return <Navigate to="/subscription-plans" />;

  if (
    location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    authState?.user?.subscription &&
    !isSessionLoading
  )
    return <Navigate to="/dashboard" />;

  return (
    <>
      <Navbar />
      <Container maxW="container.xl" minH={'calc(100vh - 96px - 105px)'}>
        {isAuthenticated && <Outlet />}
      </Container>
      <Footer />
    </>
  );
};

export default ProtectedAdminLayout;
