import { emptySplitApi } from './emptySplitApi';

export const CALL_API_REDUCER_KEY = 'callApi';

export const callApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    startDirectCall: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/createDirectCall`,
          method: 'POST',
          body: payload,
        };
      },
    }),
    getCallableUsers: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/callable-users`,
      }),
    }),
    launchCall: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/launch`,
        params,
      }),
      providesTags: ['DirectCall'],
    }),
    closeCall: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/set-closed`,
        params,
      }),
    }),
    createDirectCallSnapshot: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/call/${payload.id}/snapshot`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['DirectCall'],
    }),
    updateDirectCallSnapshot: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/call/${payload.id}/snapshot/${payload.snapshotId}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['DirectCall'],
    }),
    getDirectCallSnapshot: builder.query({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/call/${payload.id}/snapshot/${payload.snapshotId}`,
          method: 'GET',
        };
      },
    }),
    getMyCalls: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/calls`,
          method: 'GET',
          params,
        };
      },
    }),
    getCallById: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/calls/${params.id}`,
          method: 'GET',
        };
      },
    }),
    pingCallById: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/ping`,
          method: 'GET',
        };
      },
    }),
    getAdminCalls: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/admin/calls`,
          method: 'GET',
          params,
        };
      },
    }),
    stopRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/stop-recording`,
          method: 'POST',
        };
      },
    }),
    startRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/start-recording`,
          method: 'POST',
          body: params,
        };
      },
    }),
    getRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/call/${params.id}/recording/${params.recordingKey}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useStartDirectCallMutation,
  useGetCallableUsersQuery,
  useLazyLaunchCallQuery,
  useLazyCloseCallQuery,
  useUpdateDirectCallSnapshotMutation,
  useCreateDirectCallSnapshotMutation,
  useLazyGetDirectCallSnapshotQuery,
  useLazyGetMyCallsQuery,
  useGetCallByIdQuery,
  useLazyGetAdminCallsQuery,
  useLazyStopRecordingQuery,
  useLazyStartRecordingQuery,
  useLazyGetRecordingQuery,
  usePingCallByIdQuery
} = callApi;
