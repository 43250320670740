import {
  Box,
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';

const ValidateYourEmailModal = ({ show, onClose }) => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={show}
      // isCentered
      motionPreset="slideInBottom"
      size="2xl"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Validate your Email Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderRadius={20}
          paddingX={10}
          paddingTop={2}
          paddingBottom={10}
        >
          <VStack spacing={8} alignItems="flex-start">
            <Text fontSize={'xl'}>
              Validate your email address to access more features Disable
              booking a call & making any changes on enterprise admin page
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ValidateYourEmailModal;
