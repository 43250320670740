import { useState } from 'react';

import { useToast } from '@chakra-ui/react';
import { useLoginMutation } from '../redux/api/authApi';
import { axiosInstance } from '../redux/api/axiosBaseQuery';
import { checkIfEmail } from '../utils';

export const useSession = () => {
  const [login, { isLoading }] = useLoginMutation();
  const toast = useToast();
  const [sessionState, setSessionState] = useState({
    token: null,
    refreshToken: null,
    isFetching: false,
    errors: null,
  });

  const appLogin = async data => {
    try {
      setSessionState(oldState => ({
        ...oldState,
        isFetching: true,
        errors: null,
      }));
      // check if has already logged in. If so deny access
      const checkLoginResponse = await axiosInstance.get(
        `/v1/account/check-login?username=${encodeURIComponent(data.username)}`
      );
      if (checkLoginResponse.data === true) {
        toast({
          title: 'You are already logged in - Closing your previous session...',
          status: 'warning',
        });
      }
      // check if text is an email call an API and get username
      if (checkIfEmail(data.username)) {
        const usernameResponse = await axiosInstance.get(
          `/v1/account/username?email=${encodeURIComponent(data.username)}`
        );
        const username = usernameResponse.data;
        data.username = username;
      }
      const response = await login({
        username: data.username,
        password: data.password,
      });
      if (response.error) {
        toast({ status: 'error', title: response.error?.data || 'Error while login' });
      }
      setSessionState(oldState => ({ ...oldState, isFetching: false }));
      // refresh jwt so that the user gets logged

      return response;
    } catch (error) {
      console.error(error);
      setSessionState(oldState => ({
        ...oldState,
        isFetching: false,
        errors: error.response,
      }));
    }
  };

  return {
    appLogin,
    ...sessionState,
    isFetching: sessionState.isFetching || isLoading,
  };
};
