import { Box } from '@chakra-ui/react';
import React from 'react';
import './index.css';

const RightDrawerBox = ({ isOpen, children, styles }) => {
  if (!isOpen) return <></>;
  return (
    <Box
      className="slide-in-box"
      backgroundColor={'gray.100'}
      p={8}
      position={'relative'}
      shadow={'md'}
      marginTop={4}
      zIndex={2}
      right={2}
      borderRadius={10}
      w={'100%'}
      {...styles}
    >
      {children}
    </Box>
  );
};

const RightDrawerContainer = ({ children }) => {
  return (
    <Box
      position={'fixed'}
      right={8}
      top={24}
      zIndex={2}
      // height={'100vh'}
      w={['70%', '40%', '30%']}
    >
      {children}
    </Box>
  );
};

export { RightDrawerContainer, RightDrawerBox };
