import {
  Box,
  IconButton,
  Link,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { AiFillBell, AiFillCloseCircle } from 'react-icons/ai';
import { GoPrimitiveDot } from 'react-icons/go';

import { useDispatch, useSelector } from 'react-redux';
import { useNotifications } from '../../hooks/useNotifications';
import { AuthContext } from '../../providers/AuthProvider';
import { useLazyGetAppointmentQuery } from '../../redux/api/appointmentApi';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useReadNotificationsMutation,
} from '../../redux/api/notificationApi';
import {
  setAppointmentToReview,
  setOpenReview,
} from '../../redux/slices/appointmentSlice';
import { RightDrawerBox, RightDrawerContainer } from '../RightDrawer';
import { orangeColor } from '../../theme/theme';
import { useTours } from '../../providers/tours';

const Navigate = ({ item }) => {
  if (item.type === 'userAssignedToExpertRequest') {
    return (
      <Link
        isExternal={true}
        color={'blue.600'}
        href={`/telup/sysadmin/expert-requests/reviews/${item.props?.state}`}
      >
        {' '}
        Go
      </Link>
    );
  }
  if (item.type === 'expertRequestInternalNote') {
    return (
      <Link
        isExternal={true}
        color={'blue.600'}
        href={`/telup/sysadmin/expert-requests/reviews/${item.props?.state}/internal-notes`}
      >
        {' '}
        Go
      </Link>
    );
  }
};

const NotificationTray = () => {
  const { enterpriseId } = useContext(AuthContext);
  const dispatch = useDispatch();
  const tours = useTours();
  const reactTourInAction = !!tours?.currentTour;
  const [readingNotification, setReadingNotification] = useState();
  const { data: notifications, refetch: refetchNotifications } =
    useGetNotificationsQuery(null, {
      pollingInterval: 10000,
      refetchOnMountOrArgChange: true,
    });
  const authState = useSelector(store => store?.auth);
  useNotifications({ authState, notifications });
  const [readNotifications] = useReadNotificationsMutation();
  const [readNotification] = useReadNotificationMutation();
  const [getAppointment] = useLazyGetAppointmentQuery();

  const isAppointmentToReview = notifications?.records?.find(
    n => n.type === 'appointment-review' && !n.isRead
  );

  const processedReview = useCallback(
    async appointmentReview => {
      const appt = await getAppointment({
        id: appointmentReview?.props?.appointmentId,
        enterpriseId,
      });
      if (appt.isSuccess) {
        if (appointmentReview) {
          // show create appointment review
          // maybe use a redux state so that this is triggered from here or from index dashboard page
          dispatch(setOpenReview(true));
          dispatch(setAppointmentToReview(appt.data));
          console.log('is!', appt.data);
        }
      } else {
        console.log('error!');
      }
    },
    [dispatch, getAppointment]
  );

  const unreadNotifications = (notifications?.records || []).filter(
    n => !n.isRead
  );

  const handleOnReadNotification = async notification => {
    setReadingNotification(notification._id);
    await readNotification({ id: notification._id });
    setReadingNotification();
    // await refetchNotifications
  };

  useEffect(() => {
    // check if there is a review notification. Also if it was not read, if not show the modal.
    if (isAppointmentToReview) {
      processedReview(isAppointmentToReview);
    }
  }, [isAppointmentToReview, processedReview]);

  const handleOnClose = async () => {
    const result = await readNotifications({});
    //console.log('result', result)
  };

  return (
    <>
      <Popover placement="bottom" onClose={handleOnClose}>
        <PopoverTrigger>
          <IconButton
            css={css`
              position: relative !important;
            `}
            py={'2'}
            aria-label={'Cart Items'}
            size={'md'}
            variant="ghost"
            mx={2}
            _hover={{
              backgroundColor: 'rgba(255,255,255,0.1)',
            }}
            _active={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
            icon={
              <>
                <AiFillBell color="white" size={28} />
                {notifications?.unreadCount > 0 && (
                  <Box
                    as={'span'}
                    color={'white'}
                    position={'absolute'}
                    top={'0px'}
                    right={'0px'}
                    fontSize={'0.8rem'}
                    bgColor={'red'}
                    borderRadius={'lg'}
                    zIndex={9999}
                    p={'2px'}
                  >
                    {notifications?.unreadCount}
                  </Box>
                )}
              </>
            }
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverHeader fontWeight="semibold" textAlign="center">
            Notifications ({notifications?.unreadCount})
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody p={0}>
            <List spacing={1}>
              {notifications?.records?.map(item => {
                return (
                  <ListItem
                    key={item._id}
                    borderBottom="1px solid #E1E1E1"
                    px={2}
                    py={1}
                  >
                    {!item?.isRead ? (
                      <Text as="strong" fontSize={15} fontFamily="heading">
                        <ListIcon as={GoPrimitiveDot} color="orange.500" />
                        {item.title}
                        <Navigate item={item} />
                      </Text>
                    ) : (
                      <Text fontWeight="bold" fontSize={14}>
                        {item.title}
                        <Navigate item={item} />
                      </Text>
                    )}
                    <Text fontSize={13}>
                      {moment(item?.createdAt).calendar(null, {
                        sameDay: 'hh:mm A',
                      })}
                    </Text>
                  </ListItem>
                );
              })}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <RightDrawerContainer>
        {!reactTourInAction &&
          (unreadNotifications || []).map((item, i) => {
            return (
              <RightDrawerBox
                key={`un-not-${i}`}
                isOpen={true}
                styles={{ backgroundColor: 'gray.50', borderWidth: 2 }}
              >
                <Box right={0} top={0} position={'absolute'}>
                  {readingNotification === item._id ? (
                    <Spinner />
                  ) : (
                    <AiFillCloseCircle
                      fontSize={24}
                      color={orangeColor}
                      cursor={'pointer'}
                      onClick={() => handleOnReadNotification(item)}
                    />
                  )}
                </Box>
                <Box fontWeight={'bold'}>
                  <Text as="strong" fontSize={15} fontFamily="heading">
                    {/* <ListIcon as={GoPrimitiveDot} color="orange.500" /> */}
                    {item.title}
                    <Navigate item={item} />
                  </Text>
                  <Text fontSize={13}>
                    {moment(item?.createdAt).calendar(null, {
                      sameDay: 'hh:mm A',
                    })}
                  </Text>
                </Box>
              </RightDrawerBox>
            );
          })}
      </RightDrawerContainer>
    </>
  );
};

export default NotificationTray;
