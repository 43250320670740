import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setShowMessage3a,
  setShowMessage3b,
  setValidateYourEmailModalNotification
} from '../redux/slices/notificationSlice';

export const getNotificationModals = (notifications, authState) => {
  const expertRequestNotificationsPendingTechReview =
    notifications?.records?.some(
      n =>
        n.type === 'expertRequest' &&
        !n.isRead &&
        n.owner === authState?.user?._id &&
        n.props?.state === 'pending_tech_review'
    ) && authState?.user?.type.includes('tech');

  const expertRequestNotificationsApproved =
    notifications?.records?.some(
      n =>
        n.type === 'expertRequest' &&
        !n.isRead &&
        n.owner === authState?.user?._id &&
        n.props?.state === 'approved'
    ) && authState?.user?.type.includes('tech');

  const validateYourEmailNotification = notifications?.records?.find(
    n =>
      n.type === 'validate-your-email' &&
      !n.isRead &&
      n.owner === authState?.user?._id
  );

  const welcomeNotification = notifications?.records?.find(
    n => n.type === 'welcome' && !n.isRead && n.owner === authState?.user?._id
  );
  // console.log('validateYourEmailNotification', validateYourEmailNotification)
  // console.log('welcomeNotification', welcomeNotification)
  return {
    expertRequestNotificationsPendingTechReview,
    expertRequestNotificationsApproved,
    validateYourEmailNotification,
    welcomeNotification,
  };
};

export const useNotifications = ({ notifications, authState }) => {
  const dispatch = useDispatch();

  const modalNotifications = getNotificationModals(notifications, authState);

  useEffect(() => {
    if (!_.isEmpty(modalNotifications)) {
      dispatch(
        setShowMessage3a(
          modalNotifications.expertRequestNotificationsPendingTechReview
        )
      );
      dispatch(
        setShowMessage3b(modalNotifications.expertRequestNotificationsApproved)
      );
      // set this to false. Requested by Emily on 10/31
      dispatch(
        setValidateYourEmailModalNotification(
          false && modalNotifications.validateYourEmailNotification
        )
      );
      // do not show it as a modal. Show it as a Reactour
      // dispatch(
      //   setWelcomeModalNotification(modalNotifications.welcomeNotification)
      // );
    }
  }, [
    dispatch,
    modalNotifications,
    modalNotifications.expertRequestNotificationsPendingTechReview,
    modalNotifications.expertRequestNotificationsApproved,
    modalNotifications.validateYourEmailNotification,
    modalNotifications.welcomeNotification,
  ]);
};
