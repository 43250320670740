import { lazy } from 'react';
import { ROLES } from './utils';

export const signupRoutes = [
  {
    path: '/signup',
    exact: true,
    element: lazy(() => import('./pages/auth/signup')),
  },
];

export const onboardingRoutes = [
  {
    path: 'onboard-call',
    exact: true,
    element: lazy(() => import('./pages/onboard-call')),
  },
  {
    path: 'subscription-plans/:status?',
    exact: true,
    element: lazy(() => import('./pages/subscription-plans')),
  },
];

export const publicRoutes = [
  {
    path: '/login',
    exact: true,
    element: lazy(() => import('./pages/auth/login')),
  },
  {
    path: '/forgot-password',
    exact: true,
    element: lazy(() => import('./pages/auth/forgotPassword')),
  },
  {
    path: '/marketplace',
    exact: true,
    element: lazy(() => import('./pages/shop')),
    marketplace: true,
  },
  {
    path: '/marketplace/:id',
    exact: true,
    element: lazy(() => import('./pages/product')),
    marketplace: true,
  },
  {
    path: '/marketplace/workflows',
    exact: true,
    element: lazy(() => import('./pages/shop/workflow')),
    marketplace: true,
  },
  {
    path: '/marketplace/workflows/:workflowId',
    exact: true,
    element: lazy(() => import('./pages/shop/show')),
    marketplace: true,
  },
  {
    path: '/marketplace/checkout',
    exact: true,
    element: lazy(() => import('./pages/checkout')),
  },
  {
    path: '/order/:displayId/complete-account',
    exact: true,
    element: lazy(() => import('./pages/checkout/completeAccount')),
  },
  {
    path: 'onboard/how-to-signup',
    exact: true,
    element: lazy(() => import('./pages/onboard/how-to-signup')),
  },
  {
    path: 'onboard/getting-started',
    exact: true,
    element: lazy(() => import('./pages/onboard/getting-started')),
  },
  {
    path: 'onboard/calls-walkthrough',
    exact: true,
    element: lazy(() => import('./pages/onboard/calls-walkthrough')),
  },
  {
    path: 'onboard/expert-walkthrough',
    exact: true,
    element: lazy(() => import('./pages/onboard/expert-walkthrough')),
  },
  {
    path: 'onboard/subscription-plans',
    exact: true,
    element: lazy(() => import('./pages/subscription-plans')),
  },
  {
    path: 'confirm-email',
    exact: true,
    element: lazy(() => import('./pages/confirm-email')),
  },
  {
    path: 'public-profile/:userId',
    exact: true,
    element: lazy(() => import('./pages/profile/public-profile')),
  },
  {
    path: 'reset-password',
    exact: true,
    element: lazy(() => import('./pages/reset-password')),
  },
  {
    path: 'onboard/getting-started-with-smart-glasses',
    exact: true,
    element: lazy(() =>
      import('./pages/onboard/getting-started-with-smart-glasses')
    ),
  },
];

export const protectedRoutes = [
  {
    path: '/unsubscribed-enterprise-warning',
    exact: true,
    element: lazy(() => import('./pages/unsubscribed-enterprise-warning')),
  },
  {
    path: '/dashboard',
    exact: true,
    element: lazy(() => import('./pages/dashboard')),
  },
  {
    path: '/call',
    exact: true,
    element: lazy(() => import('./pages/call')),
  },
  {
    path: '/direct-call',
    exact: true,
    element: lazy(() => import('./pages/direct-call')),
  },
  {
    path: '/credit-cards/:status?',
    exact: true,
    element: lazy(() => import('./pages/credit-cards')),
  },
  {
    path: '/withdrawal-method/:status?',
    exact: true,
    element: lazy(() => import('./pages/withdrawal-method')),
  },
  {
    path: '/service-report',
    exact: true,
    element: lazy(() => import('./pages/service-report')),
  },
  {
    path: '/payment-details',
    exact: true,
    element: lazy(() => import('./pages/payment-details')),
  },
  {
    path: '/payment-details/success',
    exact: true,
    element: lazy(() => import('./pages/payment-details-success')),
  },
  {
    path: '/profile',
    exact: true,
    element: lazy(() => import('./pages/profile')),
  },
  // {
  //   path: '/profile/edit',
  //   exact: true,
  //   element: lazy(() => import('./pages/profile/edit-profile'))
  // },
  {
    path: '/profile/edit/:section',
    exact: true,
    element: lazy(() => import('./pages/profile/edit-profile-sections')),
  },
  {
    path: '/appointments/create',
    exact: true,
    element: lazy(() => import('./pages/appointments/create')),
  },
  {
    path: '/appointments/review/:id',
    exact: true,
    element: lazy(() => import('./pages/appointments/review.jsx')),
  },
  {
    path: '/workflows',
    exact: true,
    element: lazy(() => import('./pages/workflows')),
  },
  {
    path: '/expert/workflows',
    exact: true,
    element: lazy(() => import('./pages/workflows/index-expert')),
  },
  {
    path: '/expert/workflows/new',
    exact: true,
    element: lazy(() => import('./pages/workflows/create')),
  },
  {
    path: '/expert/workflows/:id/edit',
    exact: true,
    element: lazy(() => import('./pages/workflows/edit')),
  },
  {
    path: '/profile/:userId',
    exact: true,
    element: lazy(() => import('./pages/profile/public-profile')),
  },
  {
    path: '/transactions',
    exact: true,
    element: lazy(() => import('./pages/transactions')),
  },
  {
    path: '/expert-ai',
    exact: true,
    element: lazy(() => import('./pages/expert-ai/dashboard')),
  },
  {
    path: '/expert-ai/chat',
    exact: true,
    element: lazy(() => import('./pages/expert-ai/chat')),
  },
  {
    path: '/expert-vi',
    exact: true,
    element: lazy(() => import('./pages/expert-vi/expert-vi')),
  },
  {
    path: '/welcome-beta-users',
    exact: true,
    element: lazy(() => import('./pages/welcome-beta-users')),
  },
  {
    path: '/onboard/video-library',
    exact: true,
    element: lazy(() => import('./pages/onboard/video-library')),
  },
  {
    path: '/onboard/training-articles',
    exact: true,
    element: lazy(() => import('./pages/onboard/training-articles')),
  },
  {
    path: 'onboard',
    exact: true,
    element: lazy(() => import('./pages/onboard')),
  },
  {
    path: 'onboard/search',
    exact: true,
    element: lazy(() => import('./pages/onboard/search')),
  },
  {
    path: 'onboard/smart-glasses',
    exact: true,
    element: lazy(() => import('./pages/onboard/smart-glasses')),
  },
  {
    path: 'onboard/mobile-app',
    exact: true,
    element: lazy(() => import('./pages/onboard/mobile-app')),
  },
  {
    path: 'onboard/tech-walkthrough',
    exact: true,
    element: lazy(() => import('./pages/onboard/tech-walkthrough')),
  },
  {
    path: 'request-board-design',
    exact: true,
    element: lazy(() => import('./pages/request-board-design')),
  },
  {
    path: 'request-board-design/:id',
    exact: true,
    element: lazy(() => import('./pages/request-board-design/edit')),
  },
  {
    path: 'validate-email',
    exact: true,
    element: lazy(() => import('./pages/validate-email')),
  },
  {
    path: '/expert-not-approved',
    exact: true,
    element: lazy(() => import('./pages/apply-expert-from-tech')),
  },
  {
    path: 'onboard/tech-faq',
    exact: true,
    element: lazy(() => import('./pages/onboard/tech-faq')),
  },
  {
    path: 'onboard/expert-faq',
    exact: true,
    element: lazy(() => import('./pages/onboard/expert-faq')),
  },
  {
    path: 'apply-expert-from-tech',
    exact: true,
    element: lazy(() => import('./pages/apply-expert-from-tech')),
  },
  {
    path: 'enterprise-library',
    exact: true,
    element: lazy(() => import('./pages/enterprise-library')),
  },
  {
    path: 'enterprise-library/library',
    exact: true,
    element: lazy(() => import('./pages/enterprise-library/library')),
  },
  {
    path: 'enterprise-library/library/:id',
    exact: true,
    element: lazy(() => import('./pages/enterprise-library/library/edit')),
  },
  {
    path: 'calendar',
    exact: true,
    element: lazy(() => import('./pages/calendar')),
  },
  {
    path: 'onboard/getting-started-with-smart-glasses',
    exact: true,
    element: lazy(() =>
      import('./pages/onboard/getting-started-with-smart-glasses')
    ),
  },
  {
    path: 'call-logs',
    exact: true,
    element: lazy(() =>
      import('./pages/call-logs')
    ),
  },
  {
    path: 'call-logs/:id',
    exact: true,
    element: lazy(() =>
      import('./pages/call-logs/detail')
    ),
  },
  {
    path: 'spatial-editor',
    exact: true,
    element: lazy(() =>
      import('./pages/spatialEditor/editor')
    ),
  },
  {
    path: 'instructions',
    exact: true,
    element: lazy(() =>
      import('./pages/instructions/index')
    ),
  },
  {
    path: 'instructions/instruction/:id?',
    exact: true,
    element: lazy(() =>
      import('./pages/instructions/instruction')
    ),
  },
  /*
  {
    path: '/orders',
    exact: true,
    element: lazy(() => import('./pages/Orders'))
  },
  {
    path: '/products',
    exact: true,
    element: lazy(() => import('./pages/Products'))
  },
  */
];

export const adminRoutes = [
  // sysadmin
  {
    path: 'sysadmin/dashboard',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/dashboard')),
  },
  {
    path: 'sysadmin/expert-requests',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/expert-requests')),
  },
  {
    path: 'sysadmin/expert-requests/reviews/:userId',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/expert-requests/reviews')),
  },
  {
    path: 'sysadmin/expert-requests/reviews/:userId/internal-notes',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/expert-requests/reviews/internal-notes')),
  },
  {
    path: 'sysadmin/all-users',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/all-users')),
  },
  // create new user
  {
    path: 'sysadmin/sysadmin-add-user',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/add-user')),
  },
  // here the edition is different than how we create/edit enterprises because the edition have more details about the user
  // where the creation has less details.
  {
    path: 'sysadmin/all-users/:id',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/edit-user')),
  },
  {
    path: 'sysadmin/profile/edit/:section/:userId',
    exact: true,
    element: lazy(() => import('./pages/profile/edit-profile-sections')),
  },
  {
    path: 'sysadmin/enterprises',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/enterprises')),
  },
  // works as edition/creation
  {
    path: 'sysadmin/enterprises/edit/:id?',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/edit-enterprise')),
  },
  {
    path: 'sysadmin/payments',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/payments')),
  },
  {
    path: '/sysadmin/usage-dashboard',
    exact: true,
    element: lazy(() => import('./pages/sysadmin/usageInformation/usageInfo')),
  },
];

export const enterpriseRoutes = [
  // enterprise
  {
    path: 'admin/dashboard',
    exact: true,
    element: lazy(() => import('./pages/admin/dashboard')),
  },
  {
    path: 'admin/locations',
    exact: true,
    element: lazy(() => import('./pages/admin/locations')),
  },
  {
    path: 'admin/locations/:id',
    exact: true,
    element: lazy(() => import('./pages/admin/location-detail')),
  },
  {
    path: 'admin/users',
    exact: true,
    element: lazy(() => import('./pages/admin/users')),
  },
  {
    path: 'admin/new-user/:id?',
    exact: true,
    element: lazy(() => import('./pages/admin/new-user')),
  },
  {
    path: 'admin/new-team/:id?',
    exact: true,
    element: lazy(() => import('./pages/admin/new-team')),
  },
  {
    path: 'admin/hardware',
    exact: true,
    element: lazy(() => import('./pages/admin/hardware')),
  },
  {
    path: 'admin/billing',
    exact: true,
    element: lazy(() => import('./pages/admin/billing')),
  },
  {
    path: 'admin/hardware/:id',
    exact: true,
    element: lazy(() => import('./pages/admin/hardware/Edit')),
  },
  {
    path: 'admin/call-logs',
    exact: true,
    element: lazy(() =>
      import('./pages/admin/call-logs')
    ),
  },
  {
    path: 'admin/call-logs/:id',
    exact: true,
    element: lazy(() =>
      import('./pages/admin/call-logs/detail')
    ),
  },
  {
    path: '/admin/usage-dashboard',
    exact: true,
    element: lazy(() => import('./pages/admin/usageDashboard/usageDashboard')),
  },
];

export const forbiddenRoutes = [
  {
    path: 'forbidden',
    exact: true,
    element: lazy(() => import('./pages/error')),
  },
];

export const externalRoutes = [
  {
    path: 'external/expert-requests',
    exact: true,
    roles: [ROLES.EXTERNAL],
    element: lazy(() => import('./pages/external/expert-requests')),
  },
  {
    path: 'external/expert-requests/reviews/:userId',
    exact: true,
    roles: [ROLES.EXTERNAL],
    element: lazy(() => import('./pages/external/expert-requests/reviews')),
  },
];
