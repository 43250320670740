
import { emptySplitApi } from './emptySplitApi';

export const notificationApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query({
      query: () => ({
        url: 'v1/notifications',
      }),
      providesTags: ['Notification'],
    }),
    readNotifications: builder.mutation({
      query: payload => {
        return {
          url: `v1/notifications/read-all`,
          method: 'POST',
          body: payload.body,
        };
      },
      invalidatesTags: ['Notification'],
    }),
    readNotification: builder.mutation({
      query: payload => {
        return {
          url: `v1/notifications/read/${payload.id}`,
          method: 'POST',
          body: payload.body,
        };
      },
      invalidatesTags: ['Notification'],
    }),
    readAppointmentMessages: builder.mutation({
      query: id => {
        return {
          url: `v1/notifications/appointment/${id}/read-all`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Notification'],
    }),
    readAppointmentMessage: builder.mutation({
      query: id => {
        return {
          url: `v1/notifications/appointment/${id}/read`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Notification'],
    }),
    readExpertRequestMessage: builder.mutation({
      query: id => {
        return {
          url: `v1/notifications/expert-request/read`,
          method: 'POST',
        };
      },
      invalidatesTags: ['Notification'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNotificationsQuery,
  useReadNotificationsMutation,
  useReadAppointmentMessagesMutation,
  useReadAppointmentMessageMutation,
  useReadExpertRequestMessageMutation,
  useReadNotificationMutation
} = notificationApi;
