import axios from 'axios';

import { setAccessToken, setUser } from '../slices/authSlice';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api`,
  headers: {
    accept: `application/json`
  }
});

const setUpInterceptors = (getState, dispatch) => {
  
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getState()?.auth?.accessToken;
      if(token && config.url !== "/v1/auth/check-session"){
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  )

  // Remove this interceptor JWT doesn't expire
  // axiosInstance.interceptors.response.use(
  //   (res) => res,
  //   async (err) => {
  //     const originalConfig = err.config;
  //     if (originalConfig.url !== "auth/login" && err.response) {
  //       // 401 means token expired so try to refresh it
  //       if (err.response.status === 401 && !originalConfig._retry) {
  //         originalConfig._retry = true;
  //         try {
  //           // tries to refresh jwt by using refresh token
  //           const refreshToken = getState()?.auth?.accessToken;
  //           const { data } = await axiosInstance.post("/v1/auth/refresh", { refreshToken });
  //           dispatch(setAccessToken(data?.access_token))
  //           dispatch(setUser(data?.data))
  //           return axiosInstance(originalConfig);
  //         } catch (_error) {
  //           dispatch(setAccessToken(null));
  //           dispatch(setUser(null))
  //           return Promise.reject(_error);
  //         }
  //       }
  //     }
  //     return Promise.reject(err);
  //   }
  // )
}

const axiosBaseQuery = () =>
    async ({ url, method, body, params, ...requestOpts }, { getState, dispatch }) => {
      setUpInterceptors(getState, dispatch);
      try {
        const axiosOptions = {
          url: url,
          method,
          data: body,
          params,
          headers: requestOpts.headers,
          responseType: requestOpts.responseType,
        }
        
        if(/^(v1\/auth\/)/.test(url)){
          axiosOptions['withCredentials'] = true;
        }
        const result = await axiosInstance(axiosOptions)
        return { data: result.data }
      } catch (axiosError) {
        let err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }
export default axiosBaseQuery();

export { axiosInstance }