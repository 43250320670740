import { createSlice } from '@reduxjs/toolkit';

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    openReview: false,
    appointmentToReview: false,
  },
  reducers: {
    setOpenReview: (state, action) => {
      state.openReview = action.payload;
    },
    setAppointmentToReview: (state, action) => {
      state.appointmentToReview = action.payload;
    },
  },
});

export const { setOpenReview, setAppointmentToReview } =
  appointmentSlice.actions;

export default appointmentSlice.reducer;
