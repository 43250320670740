import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Message3a from './pages/dashboard/Message3a';
import Message3b from './pages/dashboard/Message3b';
import MessageIncomingAppointmentCall from './pages/dashboard/MessageIncomingAppointmentCall';
import MessageIncomingCall from './pages/dashboard/MessageIncomingCall';
import ValidateYourEmailModal from './pages/dashboard/ValidateYourEmailModal';
import WelcomeModal from './pages/dashboard/WelcomeModal';
import {
  useReadExpertRequestMessageMutation,
  useReadNotificationMutation,
} from './redux/api/notificationApi';
import {
  setDeleteIncomingCallNotification,
  setShowIncomingAppointmentCallNotification,
  setShowMessage3a,
  setShowMessage3b,
  setValidateYourEmailModalNotification,
  setWelcomeModalNotification,
} from './redux/slices/notificationSlice';
import { useLazyCloseCallQuery } from './redux/api/callApi';
import { AuthContext } from './providers/AuthProvider';
import { useContext } from 'react';
import _ from 'lodash';

const ModalMessages = () => {
  const { enterpriseId } = useContext(AuthContext);
  const location = useLocation();
  const [readExpertRequestMessage] = useReadExpertRequestMessageMutation();
  const [readNotification] = useReadNotificationMutation();
  const [closeCall] = useLazyCloseCallQuery();
  const notificationState = useSelector(store => store?.notifications);
  const dispatch = useDispatch();

  return (
    <>
      <WelcomeModal
        show={notificationState.welcomeModalNotification}
        onClose={async () => {
          await readNotification({
            id: notificationState?.welcomeModalNotification?.id,
          });
          dispatch(setWelcomeModalNotification());
        }}
      />
      <Message3a
        readExpertRequestMessage={readExpertRequestMessage}
        show={notificationState.showMessage3a}
        onClose={() => {
          dispatch(setShowMessage3a(false));
          readExpertRequestMessage();
        }}
      />
      <Message3b
        show={notificationState.showMessage3b}
        readExpertRequestMessage={readExpertRequestMessage}
        onClose={() => {
          dispatch(setShowMessage3b(false));
          readExpertRequestMessage();
        }}
      />
      <ValidateYourEmailModal
        show={notificationState.validateYourEmailModalNotification}
        onClose={async () => {
          await readNotification({
            id: notificationState?.validateYourEmailModalNotification?.id,
          });
          dispatch(setValidateYourEmailModalNotification());
        }}
      />
      {location.pathname !== '/direct-call' && (
        <MessageIncomingCall
          call={_.last(notificationState.incomingCallNotification)}
          onCancelled={() => {
            const call = _.last(notificationState.incomingCallNotification);
            dispatch(setDeleteIncomingCallNotification(call));
            const callId = call?.callId;
            if (callId) {
              closeCall({ enterpriseId, id: callId });
            }
          }}
          onClose={() => {
            const call = _.last(notificationState.incomingCallNotification);
            dispatch(setDeleteIncomingCallNotification(call));
          }}
        />
      )}
      {location.pathname !== '/call' && (
        <MessageIncomingAppointmentCall
          call={notificationState.showIncomingAppointmentCallNotification}
          onClose={() => {
            dispatch(setShowIncomingAppointmentCallNotification());
          }}
        />
      )}
    </>
  );
};

export default ModalMessages;
