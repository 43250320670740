import { Container, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import Cart from '../Cart';
import Footer from './Footer';
import { useSelector } from 'react-redux';

const ShopLayout = () => {
  const { show: showCart } = useSelector(state => state.cart);
  return (
    <>
      <Navbar />
      <Flex direction={{ base: 'row' }}>
        <Container
          maxW="container.xl"
          minH={{ base: 'calc(100vh - 96px - 105px)' }}
        >
          <Outlet />
        </Container>
        {showCart && (
          <Container
            zIndex={2}
            position={{ base: 'absolute' }}
            // position={{ sm: 'absolute', md: 'inherit' }}
            backgroundColor={'white'}
            height={{ base: 'calc(100% - 96px - 105px)' }}
            right={0}
            h={{ base: 'calc(100% - 96px - 105px)' }}
            maxW={{ base: '400px', xl: '400px' }}
            w="full"
            borderLeft={'2px solid #DFDFDF'}
            px={0}
            m={0}
          >
            <Cart />
          </Container>
        )}
      </Flex>
      <Footer />
    </>
  );
};

export default ShopLayout;
