import { createApi } from '@reduxjs/toolkit/query/react';

import apiBaseQuery from './axiosBaseQuery';
import { emptySplitApi } from './emptySplitApi';

export const CART_API_REDUCER_KEY = 'cartApi';

export const cartApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getCart: builder.query({
      query: (id) => ({
        url: `v1/cart/${id || ''}`,
      }),
      providesTags: ['Cart'],
    }),
    getStripeCards: builder.query({
      query: (id) => ({
        url: `v1/cart/${id}/cards`,
      }),
      providesTags: ['StripeCards'],
    }),
    addToCart: builder.mutation({
      query: payload => {
        return {
          url: `v1/cart/${payload.cartId}/items`,
          method: 'POST',
          body: payload.body,
        };
      },
      invalidatesTags: ['Cart'],
    }),
    updateCart: builder.mutation({
      query: payload => {
        return {
          url: `v1/cart/${payload.cartId}/items/${payload.id}`,
          method: 'PUT',
          body: payload.body,
        };
      },
      invalidatesTags: ['Cart'],
    }),
    removeCartItem: builder.mutation({
      query: payload => {
        return {
          url: `v1/cart/${payload.cartId}/items/${payload.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Cart'],
    }),
    createSetupIntent: builder.mutation({
      query: cartId => {
        return {
          url: `v1/cart/${cartId}/setup-intent`,
          method: 'POST',
        };
      },
    }),
    createPaymentIntent: builder.mutation({
      query: payload => {
        return {
          url: `v1/cart/${payload.cartId}/payment`,
          method: 'POST',
          body: payload.body
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCartQuery,
  useLazyGetCartQuery,
  useGetStripeCardsQuery,
  useLazyGetStripeCardsQuery,
  useAddToCartMutation,
  useUpdateCartMutation,
  useRemoveCartItemMutation,
  useCreateSetupIntentMutation,
  useCreatePaymentIntentMutation
} = cartApi;
