import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react'
import {FiMinus, FiPlus} from 'react-icons/fi'

const ItemQuantityInput = ({
  currentValue,
  onIncrement,
  onDecrement,
  disabled
}) => {
  return (
    <InputGroup size="sm" w="50%" maxW="100px">
      <InputLeftElement>
        <IconButton size="sm" variant="ghost" icon={<FiMinus />} onClick={onDecrement} disabled={disabled} />
      </InputLeftElement>
      <Input textAlign="center" value={currentValue} type="number" readOnly />
      <InputRightElement>
        <IconButton size="sm" variant="ghost" icon={<FiPlus />} onClick={onIncrement} disabled={disabled} />
      </InputRightElement>
    </InputGroup>
  )
}

export default ItemQuantityInput;