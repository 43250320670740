import { io } from 'socket.io-client';
import {
  setAddIncomingCallNotification,
  setDeleteIncomingCallNotification,
  setShowIncomingAppointmentCallNotification,
} from '../redux/slices/notificationSlice';

let socket;

const connectToSocket = (token, dispatch, setSocket, pathname) => {
  console.log('pathname', pathname);
  socket = io(process.env.REACT_APP_WEB_SOCKET_URL, {
    transports: ['websocket'],
    auth: {
      token,
    },
    query: {
      pathname,
    },
  });
  setSocket(socket);
  socket.on('incoming_appointment_call', data => {
    const { apptId } = data;
    dispatch(setShowIncomingAppointmentCallNotification({ apptId }));
  });
  socket.on('direct_call', data => {
    const { callId, host } = data;
    dispatch(setAddIncomingCallNotification({ callId, host }));
  });
  socket.on('direct_call_not_aswered', data => {
    const { callId, host } = data;
    dispatch(setDeleteIncomingCallNotification({ callId, host }));
  });
  socket.on('direct_call_ended', data => {
    const { callId, host } = data;
    dispatch(setDeleteIncomingCallNotification({ callId, host }));
  });
};

export { connectToSocket };

export default socket;
