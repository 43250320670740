import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react';
import { useContext } from 'react';

import { AuthContext } from '../../providers/AuthProvider';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';

const AuthUserMenu = ({ user, handleLogout, userType }) => {
  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          variant={'flat'}
          cursor={'pointer'}
          minW={0}
          rightIcon={<ChevronDownIcon />}
          color="white"
        >
          {user?.name}
        </MenuButton>
        <MenuList alignItems={'center'}>
          <MenuItem fontFamily="label" color="pink.500" onClick={handleLogout}>
            Sign Out
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const Navbar = () => {
  const { handleLogout, authState } = useContext(AuthContext);
  const location = useLocation()

  return (
    <Box bgGradient="linear(to-r, #6482C1, #473290)" px={4}>
      <Flex h={24} alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <Image
            src="/telup/logo.png"
            alt="NEWFORGE"
            objectFit="contain"
            htmlWidth={250}
          />
        </Box>
        <Stack direction={'row'} spacing={7}>
          <AuthUserMenu
            handleLogout={handleLogout}
            user={authState?.user}
            userType={authState?.defaultProfileRole}
          />
        </Stack>
      </Flex>
    </Box>
  );
};

export default function ExternalNavbar() {
  return <Navbar />;
}
