import { Container, useColorModeValue } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const PublicLayout = () => {
  const location = useLocation()
  return (
    <>
      <Navbar />
      <Container maxW="container.xl" minH={'calc(100vh - 96px - 105px)'}>
        <Outlet context={{ isPrivate: false }} />
      </Container>
      <Footer />
    </>
  );
};

export default PublicLayout;
