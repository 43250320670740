import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';

export const EnterpriseWelcomeModalContent = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <VStack spacing={8} alignItems="flex-start">
      <Text fontSize={'xl'}>
        Thank you for choosing to be a part of our community! We look forward to
        providing you with the best possible service. We're currently working
        hard to build a platform that is based on crowd-sourced knowledge and
        expertise, and we appreciate your patience as we continue to grow and
        develop.
      </Text>
      <HStack alignItems={'center'} justifyContent={'center'} w={'full'}>
        <Button
          variant="primary"
          marginY={4}
          onClick={() => {
            navigate('/admin/dashboard');
            onClose && onClose();
          }}
        >
          Go
        </Button>
      </HStack>
      <Text fontSize={'xl'}>
        Let's take you on a quick tour of NewForge Knowledge Place!
      </Text>
    </VStack>
  );
};

export const WelcomeModalContent = ({ onClose }) => {
  const { authState, isEnterprise, isUnlimitedEnterprise } =
  useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <VStack maxW='100%' spacing={8} alignItems="flex-start">
      <Text fontSize="2xl" fontWeight="bold">
        Welcome {authState?.user?.name}!
      </Text>
      <Text fontSize={'xl'}>
        Thank you for choosing to be a part of our community! We look forward to
        providing you with the best possible service. We're currently working
        hard to build a platform that is based on crowd-sourced knowledge and
        expertise, and we appreciate your patience as we continue to grow and
        develop.
      </Text>
      <Text fontSize={'xl'}>
        <strong>
          We want to make sure you have the best experience possible!
        </strong>{' '}
        We recommend scheduling an onboarding call with a member of our staff:
      </Text>
      <HStack alignItems={'center'} justifyContent={'center'} w={'full'}>
        <Button
          variant="primary"
          marginY={4}
          onClick={() => {
            navigate('/onboard-call');
            onClose && onClose();
          }}
        >
          Schedule
        </Button>
      </HStack>
      <Text fontSize={'xl'}>
        Let's take you on a quick tour of NewForge Knowledge Place!
      </Text>
    </VStack>
  );
};

/** 
 * @deprecated Welcome modal now is in Reactour
 * @param {*} param0 
 * @returns 
 */
const WelcomeModal = ({ show, onClose }) => {
  const { authState, isEnterprise, isUnlimitedEnterprise } =
    useContext(AuthContext);
  return (
    <Modal
      isOpen={show}
      isCentered
      motionPreset="slideInBottom"
      size="2xl"
      onClose={onClose}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Welcome {authState?.user?.name}!</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderRadius={20}
          paddingX={10}
          paddingTop={2}
          paddingBottom={10}
        >
          {isEnterprise || isUnlimitedEnterprise ? (
            <EnterpriseWelcomeModalContent onClose={onClose} />
          ) : (
            <WelcomeModalContent onClose={onClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WelcomeModal;
