import { isEmpty } from 'lodash';
import 'moment-timezone';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as LocalStore from 'store';
import { v4 as uuidv4 } from 'uuid';

import Loader from '../components/Loader';
import { useSession } from '../hooks/session';
import { useLazyGetUserQuery } from '../redux/api/accountApi';
import { useGetSessionQuery } from '../redux/api/authApi';
import {
  useAddToCartMutation,
  useLazyGetCartQuery,
  useUpdateCartMutation,
} from '../redux/api/cartApi';
import { emptySplitApi } from '../redux/api/emptySplitApi';
import {
  setAccessToken,
  setProfileRole,
  setSelectedEnterprise,
  setUser,
} from '../redux/slices/authSlice';
import {
  ACCOUNT_TYPE_VALUES,
  APPROVED_EXPERT,
  PRODUCT_SUBSCRIPTION_VALUES,
} from '../utils';
import { connectToFrontlineSocket, connectToSocket } from '../utils/webSockets';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [socket, setSocket] = useState();
  const {
    data: sessionData,
    isLoading,
    isFetching,
    isError,
    error: sessionError,
    refetch: refetchSession,
  } = useGetSessionQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    skip: true,
  });

  const [getUser, { isFetching: isUserFetching }] = useLazyGetUserQuery();
  const authState = useSelector(store => store?.auth);
  const isAuthenticated =
    !isEmpty(authState?.accessToken) ||
    (!isError &&
      !isFetching &&
      !isLoading &&
      !isEmpty(sessionData?.access_token));
  const isEnterprise = (authState?.user?.type || []).includes(
    ACCOUNT_TYPE_VALUES.ENTERPRISE
  );
  const isUnlimitedEnterprise = (authState?.user?.type || []).includes(
    ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE
  );
  const isSysAdmin = (authState?.user?.type || [])?.includes(
    ACCOUNT_TYPE_VALUES.SYS_ADMIN
  );
  const [fetchCart, { data: cartData, isFetching: isCartFetching }] =
    useLazyGetCartQuery();
  const [updateCartItem, { isLoading: isCartUpdating }] =
    useUpdateCartMutation();
  const [
    addCartItem,
    { isLoading: isCartItemAdding, originalArgs: addCartItemArgs },
  ] = useAddToCartMutation();

  const handleLogin = () => {};

  const handleLogout = useCallback(() => {
    dispatch(setAccessToken(null));
    dispatch(setUser(null));
    dispatch(setSelectedEnterprise(null));
    // await refetchSession()
    localStorage.removeItem('authState');
    dispatch(emptySplitApi.util.resetApiState());

    navigate('/login', { replace: true });
  }, [dispatch, navigate]);

  useEffect(() => {
    if (sessionError) {
      dispatch(setAccessToken(null));
      dispatch(setUser(null));
      // await refetchSession();
      dispatch(emptySplitApi.util.resetApiState());
    }
  }, [sessionError]);

  const handleUpdateCart = useCallback(
    async payload => {
      if (payload?._id) {
        await updateCartItem({
          cartId: cartData?._id,
          id: payload?._id,
          body: payload,
        });
      } else {
        await addCartItem({ cartId: cartData?._id, body: payload });
      }
    },
    [updateCartItem, addCartItem, cartData]
  );

  useEffect(() => {
    if (sessionData?.access_token) {
      // moment.tz.setDefault(sessionData?.data?.timezone);
      dispatch(setAccessToken(sessionData?.access_token));
      dispatch(setUser(sessionData?.data));
      dispatch(setProfileRole(sessionData?.data?.type?.[0]));
    }
  }, [sessionData, dispatch]);

  useEffect(() => {
    if (sessionData?.access_token || authState?.accessToken) {
      // socket io connection
      connectToSocket(
        sessionData?.access_token || authState?.accessToken,
        dispatch,
        setSocket,
        location.pathname
      );
    }
  }, [sessionData?.access_token, authState?.accessToken]);

  useEffect(() => {
    getUser().then(userObject => {
      const user = userObject.data;
      if (userObject) {
        dispatch(setUser(user));
        if (user.parent) {
          dispatch(
            setSelectedEnterprise({
              value: user.parent?._id,
              label: `cname: ${user.parent?.company_name} name: ${user.parent?.name} userId: ${user.parent?.ubimaxId} (default)`,
              id: user.parent?._id,
              _id: user.parent?._id,
            })
          );
        } else {
          dispatch(
            setSelectedEnterprise({
              value: user._id,
              label: `cname: ${user.company_name} name: ${user.name} userId: ${user.ubimaxId} (default)`,
              id: user._id,
              _id: user._id,
            })
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    const getCart = async () => {
      let session = LocalStore.get('cart_session');
      if (!session) {
        session = uuidv4();
        LocalStore.set('cart_session', session);
      }
      await fetchCart(session);
    };
    getCart();
  }, [fetchCart, isAuthenticated]);

  useEffect(() => {
    const onStorageUpdate = e => {
      const { key, value, url } = e;
      // console.log('new state',e, key, value, url);
      // if auth changed and was triggered from an internal page (different than /login)
      if (key === 'authState' && !url.includes('/login')) {
        if (!value) {
          // logout
          handleLogout();
        }
      }
    };
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [handleLogout]);

  const createNewCartSession = async () => {
    LocalStore.remove('cart_session');
    const session = uuidv4();
    LocalStore.set('cart_session', session);
    await fetchCart(session);
  };

  const getLocalProfileRole = () => {
    const role = LocalStore.get('_ptex');
    return role || 'tech';
  };

  const onProfileRoleChange = useCallback(
    role => {
      dispatch(setProfileRole(role));
    },
    [dispatch]
  );

  const getEnterpriseId = () => {
    // if picked an enterprise (only sysadmin users)
    if (authState.selectedEnterprise) {
      return authState.selectedEnterprise._id;
    }
    if (
      [
        PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE,
        PRODUCT_SUBSCRIPTION_VALUES.UNLIMITED_ENTERPRISE,
      ].includes(authState?.user?.subscription?.product?.name)
    ) {
      // the user is an enterprise, return user id
      return authState?.user?._id;
    }
    // child user just return parent enterprise id. Child user could be an admin too.
    return authState?.user?.parent?._id || authState?.user?._id;
  };

  const enterpriseId = getEnterpriseId();

  return (
    <AuthContext.Provider
      value={{
        authState,
        isEnterprise,
        isUnlimitedEnterprise,
        isSysAdmin,
        isPaidUser: !!authState?.user?.parent?.name, // child paid enterprise
        isUnlimitedPaidUser:
          authState?.user?.type?.includes(
            ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE
          ) && authState?.user?.unlimitedPaid,
        isEnterprisePaidUser:
          authState?.user?.subscription &&
          authState?.user?.subscription?.product?.name ===
            PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE,
        cartState: cartData,
        isAuthenticated,
        isCartLoading: isCartFetching || isCartUpdating || isCartItemAdding,
        addCartItemArgs,
        isSessionLoading: isFetching || isUserFetching,
        handleLogin,
        handleLogout,
        handleUpdateCart,
        createNewCartSession,
        enterpriseId, // used for sending on each API call to decide the enterprise. If enterprise just use the user id
        onProfileRoleChange,
        socket,
        setSocket,
      }}
    >
      {(isLoading || isFetching) && <Loader />}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
