import {
  extendTheme,
  withDefaultVariant,
  withDefaultProps,
} from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const CustomSteps = {
  ...Steps,
  baseStyle: props => {
    return {
      ...Steps.baseStyle(props),
      icon: {
        ...Steps.baseStyle(props).icon,
        strokeWidth: '1px',
      },
      connector: {
        ...Steps.baseStyle(props).connector,
        borderTopWidth: '5px',
      },
      stepIconContainer: {
        ...Steps.baseStyle(props).stepIconContainer,
        borderWidth: '8px',
      },
    };
  },
};

export const orangeColor = '#F5AD56';

const buttonVariants = {
  primary: {
    bg: orangeColor,
    color: '#FFFFFF',
    textTransform: 'uppercase',
    borderWidth: '1px',
    borderColor: orangeColor,
    _hover: {
      color: orangeColor,
      bg: '#fff',
      borderColor: orangeColor,
      borderWidth: '1px',
    },
  },
  primaryOutline: {
    color: orangeColor,
    textTransform: 'uppercase',
    borderWidth: '1px',
    borderColor: orangeColor,
    _hover: {
      bg: orangeColor,
      color: '#FFFFFF',
      borderColor: orangeColor,
      borderWidth: '1px',
    },
  },
  accept: {
    color: 'green',
    textTransform: 'uppercase',
    borderWidth: '1px',
    borderColor: 'green',
    _hover: {
      bg: 'green',
      color: '#FFFFFF',
      borderColor: 'green',
      borderWidth: '1px',
    },
  },
  cancel: {
    color: 'red',
    textTransform: 'uppercase',
    borderWidth: '1px',
    borderColor: 'red',
    _hover: {
      bg: 'red',
      color: '#FFFFFF',
      borderColor: 'red',
      borderWidth: '1px',
    },
  },
};

const theme = extendTheme(
  {
    fonts: {
      body: 'Affogato-Regular',
      heading: 'Affogato-Bold',
      label: 'Affogato-Medium',
    },
    colors: {
      title: '#DE9769',
    },
    components: {
      FormLabel: {
        baseStyle: {
          fontFamily: 'label',
        },
      },
      Button: {
        baseStyle: {
          fontFamily: 'label',
        },
        variants: buttonVariants,
      },
      Checkbox: {
        baseStyle: {
          fontFamily: 'label',
        },
      },
      Steps: CustomSteps,
      Avatar: {
        variants: {
          square: {
            borderRadius: '14px',
            borderWidth: '10px',
          },
        },
      },
    },
  },
  withDefaultVariant({
    variant: 'outline',
    components: ['Input', 'NumberInput', 'PinInput'],
  }),
  withDefaultProps({
    defaultProps: {
      size: 'lg',
    },
    fontFamily: 'label',
    components: ['Input', 'NumberInput', 'PinInput', 'FormLabel', 'Button'],
  })
);

export default theme;
