import 'moment-timezone';
import { useContext, forwardRef, useRef, useImperativeHandle } from 'react';

import { useDispatch } from 'react-redux';
import Tour from 'reactour';
import { useTours } from '../../providers/tours';
import { setIsNavBarMenuOpen } from '../../redux/slices/authSlice';
import { AuthContext } from '../../providers/AuthProvider';
import { TOURS } from '../../providers/tours/index';
import { Box, Text } from '@chakra-ui/react';
import {
  EnterpriseWelcomeModalContent,
  WelcomeModalContent,
} from '../../pages/dashboard/WelcomeModal';
import './index.css';

export const TourDashboardWrapper = forwardRef(({ children }, ref) => {
  const dispatch = useDispatch();
  const { authState, isEnterprise, isUnlimitedEnterprise } =
    useContext(AuthContext);
  const tourRef = useRef(null);
  const tours = useTours();
  const showTour = tours.currentTour === TOURS.DASHBOARD_TOUR_COMPLETED;

  useImperativeHandle(ref, () => ({
    goToNext() {
      goToNext();
    },
  }));

  const steps = [
    {
      // selector: '[data-tour="welcome"]',
      content: () => (
        <Box maxW="100%">
          {/* TODO: Ask Emiliy if go to book onboarding call always or not!! */}
          {isEnterprise || isUnlimitedEnterprise ? (
            // <EnterpriseWelcomeModalContent />
            <WelcomeModalContent />
          ) : (
            <WelcomeModalContent />
          )}
        </Box>
      ),
    },
    {
      selector: '[data-tour="nav-bar"]',
      content: "This is the navigation bar. Let's click your name...",
    },
    {
      selector: '[data-tour="nav-bar-2"]',
      content:
        'Use the Edit Profile page to make changes to your public profile. Go ahead and try it!',
    },
    ...(isEnterprise || isUnlimitedEnterprise
      ? [
          {
            selector: '[data-tour="enterprise-admin"]',
            content: 'This is your admin page. Go ahead and check it out!',
          },
        ]
      : []),
    {
      selector: '[data-tour="my-appointments-1"]',
      content: `This is where any appointments you make will be. ${
        authState?.defaultProfileRole === 'tech'
          ? 'You can also look for an expert by using the Find an Expert button!'
          : ''
      }`,
    },
    ...(authState?.defaultProfileRole === 'tech'
      ? [
          {
            selector: '[data-tour="my-appointments-2"]',
            content:
              'To book a call with an expert click the Find An Expert button. Try it out!',
          },
        ]
      : []),
    // {
    //   selector: '[data-tour="my-appointments-3"]',
    //   content: 'This is your dashboard...',
    // },
    {
      selector: '[data-tour="my-appointments-4"]',
      content:
        'This is the Training Library, where you can schedule a free onboarding call and view training articles and videos on how to use Knowledge Place.',
    },
    {
      selector: '[data-tour="my-appointments-5"]',
      content:
        'This is the Shop page, where you can buy hardware like smartglasses.',
    },
    ...(authState?.defaultProfileRole !== 'tech'
      ? [
          {
            selector: '[data-tour="my-appointments-6"]',
            content:
              "Here's the Transactions page, use this to monitor your transactions.",
          },
        ]
      : []),
    {
      selector: '[data-tour="my-appointments-7"]',
      content: () => (
        <Text>
          Having a hard time finding an expert? Use the request board to
          publicly post what you need help with.{'\n\n'}
          <Text mt={2}>
            As an expert, use the Request Board to find potential clients.
          </Text>
        </Text>
      ),
    },
    {
      selector: '[data-tour="my-appointments-8"]',
      content:
        'Use the Service Reports page to keep track of what calls you had, what was discussed, and what solutions you tried.',
    },
    {
      selector: '[data-tour="my-appointments-ai"]',
      content:
        'Looking for a quick troubleshooting answer? Or what about a work instruction to use on your smartglasses? ExpertAI can do all that and more!',
    },
    {
      selector: '[data-tour="my-appointments-expertWI"]',
      content:
        "Use this tile to upload, download, and share materials like manuals, work instructions, and SOP's with the rest of your enterprise.",
    },
    ...(isEnterprise || isUnlimitedEnterprise
      ? [
          {
            selector: '[data-tour="enterprise-library"]',
            content:
              'This is the Enterprise Library, where you can share manuals, PDFs, SOPs, and much more with others in your enterprise.',
          },
        ]
      : []),
    ...(isEnterprise || isUnlimitedEnterprise
      ? [
          {
            selector: '[data-tour="callable-users"]',
            content:
              'This is your list of callable users. These are people in your enterprise you have the ability to call. ',
          },
        ]
      : []),
    {
      selector: '[data-tour="my-appointments-9"]',
      content:
        'Let us know what you think! Use our feedback form to help us continue to improve.',
    },
  ];

  // open navbar menu to show the options...
  const onStepChanged = step => {
    if (!showTour) return;
    if (step === 2) {
      // open menu
      dispatch(setIsNavBarMenuOpen(true));
    }
  };

  const goToNext = () => {
    if (tourRef.current) {
      tourRef.current.nextStep();
    }
  };

  return (
    <>
      {children}
      <Tour
        ref={tourRef}
        className="custom-reactour"
        steps={steps}
        isOpen={showTour}
        getCurrentStep={onStepChanged}
        onRequestClose={() => {
          tours.handleOnTourFinished();
        }}
      />
    </>
  );
});
