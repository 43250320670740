import { emptySplitApi } from './emptySplitApi';

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getSession: builder.query({
      query: () => ({
        url: 'v1/auth/check-session',
      }),
      providesTags: ['UserSession'],
    }),
    loginDataByJwt: builder.mutation({
      query: body => {
        return {
          url: 'v1/auth/login-data-by-jwt',
          method: 'POST',
          body,
        };
      },
    }),
    login: builder.mutation({
      query: body => {
        return {
          url: 'v1/auth/login',
          method: 'POST',
          body,
        };
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'v1/auth/logout',
        method: 'POST',
      }),
    }),
    createAccount: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/signup',
          method: 'POST',
          body,
        };
      },
    }),
    confirmEmail: builder.mutation({
      query: body => {
        return {
          url: `v1/account/user/validate?code=${body.code}`,
          method: 'POST',
          body,
        };
      },
      // TODO: fix this and put 'User' Tag later
      invalidatesTags: ['Experts'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useLoginDataByJwtMutation,
  useLogoutMutation,
  useCreateAccountMutation,
  useGetSessionQuery,
  useConfirmEmailMutation,
} = authApi;
