import 'moment-timezone';
import { useContext } from 'react';

import Tour from 'reactour';
import { AuthContext } from '../../providers/AuthProvider';
import { TOURS, useTours } from '../../providers/tours/index';

export const TourEnterpriseDashboardWrapper = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const tours = useTours();
  const showTour =
    tours.currentTour === TOURS.ENTERPRISE_DASHBOARD_TOUR_COMPLETED;
  const steps = [
    {
      selector: '[data-tour="admin-dash-home"]',
      content: 'This is the page where you can manage all aspects of your enterprise',
    },
    {
      selector: '[data-tour="admin-dash-location"]',
      content: 'This is Locations, here you can keep track of who works where.',
    },
    {
      selector: '[data-tour="admin-dash-user-management"]',
      content: 'From the User Management page, you can create & manage your users. ',
    },
    {
      selector: '[data-tour="admin-dash-billing"]',
      content: 'On the Billing page, you can manage how your enterprise pays for Knowledge Place.',
    },
    {
      selector: '[data-tour="admin-dash-hardware"]',
      content: 'Use the hardware page to track hardware within your enterprise. ',
    },
    {
      selector: '[data-tour="admin-dash-usage-info"]',
      content: 'Use the hardware page to track hardware within your enterprise. ',
    },
  ];

  return (
    <>
      {children}
      <Tour
        steps={steps}
        isOpen={showTour}
        onRequestClose={() => {
          tours.handleOnTourFinished();
        }}
      />
    </>
  );
};
