import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider';

const ProtectedOnboardingLayout = ({ history }) => {
  const { isAuthenticated, isSessionLoading, authState } =
    useContext(AuthContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isSubscriptionSuccess = searchParams.get('isSubSuccess');
  const isUpgrade = searchParams.get('isUpgrade');

  if (!isAuthenticated && !isSessionLoading && !authState?.user?._id)
    return <Navigate to="/login" />;

  // force choose plan ONLY if enterprise
  if (
    // authState?.user?.validated &&
    !location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    !authState?.user?.subscription?.product?.name &&
    !isSessionLoading &&
    ['enterprise', 'unlimited_enterprise'].includes(
      authState?.defaultProfileRole
    ) &&
    !authState?.user?.parent // add this for the case when we have admin enterprise users. These users will have a parent and enterprise type.
  )
    return <Navigate to="/subscription-plans" />;

  if (
    location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    !authState?.user?.subscription &&
    !authState?.user?.subscription?.product?.name &&
    isSubscriptionSuccess &&
    !isSessionLoading
  )
    // keep loading if succeeded but the user wasn't updated yet
    return;

  // if subscription succeeded go to onboard section
  if (
    location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    authState?.user?.subscription &&
    authState?.user?.subscription?.product?.name &&
    (!isUpgrade || isSubscriptionSuccess) &&
    !isSessionLoading
  )
    return <Navigate to="/onboard-call" />;

  return (
    <>
      <Outlet context={{ isPrivate: true, isOnboard: true }} />
    </>
  );
};

export default ProtectedOnboardingLayout;
