import {
  Box,
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { baseURL } from '../../utils';

const MessageIncomingCall = ({ onClose, onCancelled, call }) => {
  return (
    <Modal
      isOpen={!!call}
      // isCentered
      motionPreset="slideInBottom"
      size="md"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={'center'}>
          <Text>Incoming Call from</Text>
          <Text>{call?.host}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <HStack w={'full'} justifyContent={'space-between'}>
            <Button
              variant={'accept'}
              onClick={() => {
                onClose();
                window.open(
                  `${baseURL}/direct-call?callId=${call?.callId}`,
                  '_blank'
                );
              }}
            >
              Accept
            </Button>
            <Button
              variant={'cancel'}
              onClick={() => {
                onCancelled();
              }}
            >
              Decline
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MessageIncomingCall;
