export const APPROVED_EXPERT = 'approved';
export const UNAPPROVED_EXPERT = 'unapproved';
export const PENDING_ADMIN_REVIEW = 'pending_admin_review';
export const PENDING_TECH_REVIEW = 'pending_tech_review';

export const formatDecimal2Places = number => number.toFixed(2);

/**
 *
 * @param {*} textItem text to search through
 * @param {*} keyword keyword
 */
export const searchText = (textItem, keyword) => {
  const pattern = new RegExp(keyword, 'i');
  return textItem.match(pattern);
};

export const formatCurrency = num => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(num);
};

export const checkIfEmail = text => {
  const regexExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
  return regexExp.test(text);
};

export const jsonToFormData = json => {
  const formData = new FormData();

  Object.keys(json).forEach(key => {
    formData.append(key, json[key]);
  });

  return formData;
};

export const jsonArrToFormData = array => {
  const list = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const formData = jsonToFormData(element);
    list.push(formData);
  }
  return list;
};

export const processDownloadedFile = response => {
  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  let fileName = (
    response.headers['content-disposition'] ||
    response.headers['Content-Disposition']
  ).split('filename=')[1];
  fileName = fileName.replaceAll("'", '').replaceAll('"', '');
  fileLink.setAttribute('download', fileName);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};

export const enterpriseLibraryLabel = value => {
  switch (value) {
    case 'SCHEMATIC':
      return 'Schematic';
    case 'MANUAL':
      return 'Manual';
    case 'SLIDE_DECK':
      return 'Slide Deck';
    case 'IMAGE':
      return 'Diagram';
    case 'VIDEO':
      return 'Video';
    case 'AI_CHAT':
      return 'AI Chat';
    case 'VIDEO_INSTRUCTIONS':
      return 'Video Instructions';
    default:
      return '';
  }
};

export const ROLES = {
  EXTERNAL: 'external',
  ENTERPRISE: 'Enterprise',
  FREE: 'Free',
};

export const PRODUCT_SUBSCRIPTION_VALUES = {
  ENTERPRISE: 'Enterprise Membership',
  UNLIMITED_ENTERPRISE: 'Unlimited Enterprise',
  FREE: 'FREE',
};

export const ACCOUNT_TYPE_VALUES = {
  EXPERT: 'expert',
  TECH: 'tech',
  SYS_ADMIN: 'sysadmin',
  EXTERNAL: 'external',
  ENTERPRISE: 'enterprise',
  UNLIMITED_ENTERPRISE: 'unlimited_enterprise',
};

export const baseURL = `${window.location.origin}/telup`;

export const wait = ms => {
  return new Promise((success, reject) => {
    setTimeout(() => success('ok'), ms);
  });
};
