import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    user: null,
    defaultProfileRole: null,
    signUpData: false,
    isNavBarMenuOpen: false,
    selectedEnterprise: null
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setProfileRole: (state, action) => {
      state.defaultProfileRole = action.payload;
    },
    setSignUpData: (state, action) => {
      state.signUpData = action.payload;
    },
    setIsNavBarMenuOpen: (state, action) => {
      state.isNavBarMenuOpen = action.payload;
    },
    setSelectedEnterprise: (state, action) => {
      state.selectedEnterprise = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setUser,
  setProfileRole,
  setSignUpData,
  setIsNavBarMenuOpen,
  setSelectedEnterprise,
  setRefreshToken
} = authSlice.actions;

export default authSlice.reducer;
