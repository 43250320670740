import { createApi } from '@reduxjs/toolkit/query/react';

import apiBaseQuery from './axiosBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: apiBaseQuery,
  tagTypes: [
    'PaymentIntent',
    'CreditCards',
    'BankAccount',
    'Amount',
    'Experts',
    'Appointment',
    'AppointmentComments',
    'Account',
    'Tags',
    'OfficeHours',
    'Users',
    'UserSession',
    'Billing',
    'Cart',
    'Checkout',
    'Workflows',
    'Notification',
    'Service-Report',
    'Review',
    'Hosts',
    'Request-Board-Design',
    'Expert-Requests',
    'EnterpriseLibrary',
    'EnterpriseUsers',
    'Teams',
    'Hardware',
    'Invoices',
    'Enterprises',
    'Payments',
    'DirectCall',
    'AppointmentCall',
    'Process',
  ],
  endpoints: () => ({}),
});
