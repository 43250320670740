import { emptySplitApi } from './emptySplitApi';

export const appointmentApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getExpertSearchByTags: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/expert-search/tags`,
        params,
      }),
      providesTags: ['Experts'],
    }),
    getFutureAppointments: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/future`,
        params,
      }),
      providesTags: ['Appointment'],
    }),
    getCheckReviews: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/reviews`,
        params,
      }),
      providesTags: ['Review'],
    }),
    getEmptyNoServiceReportAppointments: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/no-service-report`,
        params,
      }),
      providesTags: ['Appointment'],
    }),
    getUnclosedAppointments: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/not-closed`,
        params,
      }),
      providesTags: ['Appointment'],
      // invalidatesTags: ['Appointment']
    }),
    closeAppointment: builder.query({
      query: payload => ({
        url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/set-closed`,
      }),
      // providesTags: ['Appointment'],
      invalidatesTags: ['Appointment'],
    }),
    closeOnboarding: builder.query({
      query: payload => ({
        url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/onboarding/set-closed`,
      }),
      // providesTags: ['Appointment'],
      invalidatesTags: ['Appointment'],
    }),
    createAppointment: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/appointment/create`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Appointment'],
    }),
    createOnboardingCall: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/appointment/onboarding/create`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Appointment'],
    }),
    createAppointmentComment: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/comment`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['AppointmentComments'],
    }),
    updateAppointmentStatus: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/status/${payload.id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Appointment'],
    }),
    deleteAppointment: builder.mutation({
      query: ({ id, enterpriseId }) => {
        return {
          url: `v1/enterprise/${enterpriseId}/appointment/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Appointment'],
    }),
    startCall: builder.query({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/call/create`,
          method: 'POST',
          body: payload,
        };
      },
      providesTags: ['AppointmentCall'],
    }),
    stopAppointmentRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/appointment/${params.id}/call/stop-recording`,
          method: 'POST',
        };
      },
    }),
    startAppointmentRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/appointment/${params.id}/call/start-recording`,
          method: 'POST',
          body: params,
        };
      },
    }),
    getAppointmentRecording: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/appointment/${params.id}/call/recording/${params.recordingKey}`,
          method: 'GET',
        };
      },
    }),
    updateWhiteboard: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/snapshot`,
          method: 'PUT',
          body: payload,
        };
      },
    }),
    getWhiteboardData: builder.query({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/snapshot`,
          method: 'GET',
        };
      },
    }),
    createServiceReport: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/service-report`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Service-Report'],
    }),
    createAppointmentReview: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.appointmentId}/review`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Review'],
    }),
    getReviews: builder.query({
      query: params => {
        return {
          url: `v1/enterprise/${params.enterpriseId}/appointment/reviews/${params.userId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['Review'],
    }),
    updateServiceReport: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/service-report/${payload.id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Service-Report'],
    }),
    deleteServiceReport: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/service-report/${payload._id}`,
          method: 'DELETE',
          body: payload,
        };
      },
      invalidatesTags: ['Service-Report'],
    }),
    getServiceReport: builder.query({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/service-report`,
          method: 'GET',
        };
      },
      providesTags: ['Service-Report'],
    }),
    getAppointmentComments: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/${params.id}/comments`,
        params,
      }),
      providesTags: ['AppointmentComments'],
    }),
    getAppointment: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/${params.id}`,
        params,
      }),
      providesTags: ['Appointment'],
    }),
    getHosts: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/appointment/host-search`,
        params,
      }),
      providesTags: ['Hosts'],
    }),
    createAppointmentSnapshot: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/snapshot`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['AppointmentCall'],
    }),
    updateAppointmentSnapshot: builder.mutation({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/snapshot/${payload.snapshotId}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['AppointmentCall'],
    }),
    getAppointmentSnapshot: builder.query({
      query: payload => {
        return {
          url: `v1/enterprise/${payload.enterpriseId}/appointment/${payload.id}/snapshot/${payload.snapshotId}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetExpertSearchByTagsQuery,
  useLazyGetExpertSearchByTagsQuery,
  useGetHostsQuery,
  useGetUnclosedAppointmentsQuery,
  useGetFutureAppointmentsQuery,
  useLazyGetFutureAppointmentsQuery,
  useCreateAppointmentMutation,
  useCreateOnboardingCallMutation,
  useCreateAppointmentCommentMutation,
  useGetAppointmentCommentsQuery,
  useUpdateAppointmentStatusMutation,
  useLazyStartCallQuery,
  useDeleteAppointmentMutation,
  useLazyCloseAppointmentQuery,
  useLazyCloseOnboardingQuery,
  useCreateServiceReportMutation,
  useGetServiceReportQuery,
  useUpdateServiceReportMutation,
  useDeleteServiceReportMutation,
  useLazyGetEmptyNoServiceReportAppointmentsQuery,
  useGetEmptyNoServiceReportAppointmentsQuery,
  useCreateAppointmentReviewMutation,
  useLazyGetAppointmentQuery,
  useGetAppointmentQuery,
  useGetReviewsQuery,
  useUpdateWhiteboardMutation,
  useGetWhiteboardDataQuery,
  useUpdateAppointmentSnapshotMutation,
  useCreateAppointmentSnapshotMutation,
  useLazyGetAppointmentSnapshotQuery,
  useLazyStartAppointmentRecordingQuery,
  useLazyStopAppointmentRecordingQuery
} = appointmentApi;
