import {
  Box,
  Button,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../providers/AuthProvider';
import { baseURL } from '../../utils';

const Message3a = ({ show, onClose, readExpertRequestMessage }) => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={show}
      // isCentered
      motionPreset="slideInBottom"
      size="2xl"
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Hi, {authState?.user?.name}!</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderRadius={20}
          paddingX={10}
          paddingTop={2}
          paddingBottom={10}
        >
          <VStack spacing={8} alignItems="flex-start">
            <Text fontSize={'xl'}>
              After reviewing your application, a member of our team has
              requested these changes:
              <Text mt={4} fontStyle={'italic'}>
                {authState?.user?.technicianRequest?.optionalStatusMessage}
              </Text>
            </Text>
            <Text fontSize={'xl'}>
              Please make those changes{' '}
              <Link
                fontSize={'xl'}
                color={'purple.500'}
                onClick={() => {
                  readExpertRequestMessage()
                }}
                href={`${baseURL}/profile`}
                isExternal
              >
                here
              </Link>
            </Text>
            <Text fontSize={'xl'}>
              <strong>If you have any questions: please refer to our</strong>{' '}
              <Link
                color={'purple.500'}
                href={`${baseURL}/onboard`}
                isExternal
              >
                Training Library
              </Link>{' '}
              or contact us at{' '}
              <Link
                color={'purple.500'}
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                fontWeight="bold"
                isExternal
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </Link>
            </Text>
            <Box>
              <Text fontSize={'xl'} fontWeight="bold">
                We'd love to hear your feedback as we continue to improve our
                platform. Check out our feedback form on the bottom of your
                screen or click{' '}
                <Link
                  color="purple.500"
                  href="https://forms.gle/V4YH573oQeyzjf8K7"
                  target={'_blank'}
                  fontWeight="bold"
                >
                  Here
                </Link>{' '}
                to leave your feedback 😊
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Message3a;
