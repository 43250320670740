import {
  Box,
  Button,
  HStack,
  ModalContent,
  ModalFooter,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  RightDrawerBox,
  RightDrawerContainer,
} from '../../components/RightDrawer';
import { baseURL } from '../../utils';

const MessageIncomingAppointmentCall = ({ onClose, call }) => {
  if (!call) return;
  return (
    <RightDrawerContainer>
      <RightDrawerBox isOpen={!!call} onClose={onClose}>
        <VStack spacing={4}>
          <Box textAlign={'center'} fontWeight={'bold'}>
            <Text>{call?.host} Started Appointment Meeting</Text>
          </Box>
          <HStack w={'full'} justifyContent={'space-between'}>
            <Button
              variant={'accept'}
              onClick={() => {
                onClose();
                window.open(
                  `${baseURL}/call?appointmentId=${call.apptId}`,
                  '_blank'
                );
              }}
            >
              Join
            </Button>
            <Button
              variant={'cancel'}
              onClick={() => {
                onClose();
              }}
            >
              Ignore
            </Button>
          </HStack>
        </VStack>
      </RightDrawerBox>
    </RightDrawerContainer>
  );
};

export default MessageIncomingAppointmentCall;
