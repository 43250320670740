import 'moment-timezone';
import { useContext, useRef } from 'react';
import Tour from 'reactour';
import { useTours } from '../../providers/tours';
import { AuthContext } from '../../providers/AuthProvider';
import { TOURS } from '../../providers/tours/index';

/**
 * @deprecated
 * Don't used
 * @param {*} param0
 * @returns
 */
export const TourNavbarWrapper = ({ children }) => {
  const tourRef = useRef(null);
  const { authState, isAuthenticated, isEnterprise, isUnlimitedEnterprise } =
    useContext(AuthContext);
  const tours = useTours();
  const showTour =
    false && tours.currentTour === TOURS.DASHBOARD_TOUR_COMPLETED;

  const steps = [
    {
      selector: '[data-tour="nav-bar"]',
      content: "This is the navigation bar. Let's click your name...",
    },
    {
      selector: '[data-tour="nav-bar-2"]',
      content:
        'Use the Edit Profile page to make changes to your public profile. Go ahead and try it!',
    },
    ...(!isEnterprise && !isUnlimitedEnterprise
      ? [
          {
            selector: '[data-tour="nav-bar-3"]',
            content:
              'Make sure to set up an account to get paid/make payments!',
          },
        ]
      : []),
  ];

  return (
    <>
      {children}
      <Tour ref={tourRef} steps={steps} isOpen={showTour} />
    </>
  );
};
