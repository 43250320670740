import { Box, IconButton } from '@chakra-ui/react';
import { css } from '@emotion/react';

function IconBadge({ count, icon }) {
  const InnerIcon = icon
  return (
    <IconButton
      css={css`
        position: relative !important;
      `}
      py={'2'}
      aria-label={'Cart Items'}
      size={'md'}
      variant="ghost"
      mx={2}
      _hover={{
        backgroundColor: 'rgba(255,255,255,0.1)',
      }}
      _active={{ backgroundColor: 'rgba(255,255,255,0.1)' }}
      icon={
        <>
          <InnerIcon color='white' size={28} />
          {count > 0 && (
            <Box
              as={'span'}
              color={'white'}
              position={'absolute'}
              top={'0px'}
              right={'0px'}
              fontSize={'0.8rem'}
              bgColor={'red'}
              borderRadius={'lg'}
              zIndex={9999}
              p={'2px'}
            >
              {count}
            </Box>
          )}
        </>
      }
    />
  );
}

export default IconBadge;
