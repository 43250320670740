import { Stack, HStack, Text, Link, Image, Flex } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Stack alignItems="center" py={2} borderTop="1px solid #DFDFDF">
      <Text as="b">Buhler Vision</Text>
      <HStack spacing={1} w="100%" justifyContent={'center'}>
        <Link
          color="#17365E"
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
          fontWeight="bold"
        >
          {process.env.REACT_APP_SUPPORT_EMAIL}
        </Link>
        <Link
          data-tour="my-appointments-9"
          position={'absolute'}
          right="10px"
          color="#17365E"
          href="https://forms.gle/V4YH573oQeyzjf8K7"
          target={'_blank'}
          fontWeight="bold"
        >
          Feedback?
        </Link>
      </HStack>
      <Text>
        © Copyrights 2023 newforge.pro | All rights reserved | v
        {process.env.REACT_APP_VERSION}
      </Text>
    </Stack>
  );
};

export default Footer;
