import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from './slices/authSlice';
import appointmentReducer from './slices/appointmentSlice';
import cartSliceReducer from './slices/cartSlice';
import notificationSliceReducer from './slices/notificationSlice';

import { emptySplitApi } from './api/emptySplitApi';

const setUpStore = () => {
  const store = configureStore({
    reducer: {
      auth: authReducer,
      appointment: appointmentReducer,
      cart: cartSliceReducer,
      notifications: notificationSliceReducer,
      [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    },
    middleware: getDM => [
      ...getDM(),
      emptySplitApi.middleware,
    ],
    preloadedState: {
      auth: localStorage.getItem('authState')
        ? JSON.parse(localStorage.getItem('authState'))
        : undefined,
    },
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = setUpStore();
