import { Suspense, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AuthProvider, { AuthContext } from './providers/AuthProvider';
import ProtectedLayout from './components/layouts/ProtectedLayout';
import PublicLayout from './components/layouts/PublicLayout';
import ShopLayout from './components/layouts/ShopLayout';

import {
  publicRoutes,
  protectedRoutes,
  adminRoutes,
  forbiddenRoutes,
  externalRoutes,
  enterpriseRoutes,
  signupRoutes,
  onboardingRoutes,
} from './routes';
import Loader from './components/Loader';
import ProtectedAdminLayout from './components/layouts/ProtectedAdminLayout';
import { ROLES } from './utils';
import ExternalLayout from './components/layouts/ExternalLayout';
import ProtectedEnterpriseLayout from './components/layouts/ProtectedEnterpriseLayout';
import ProtectedOnboardingLayout from './components/layouts/ProtectedOnboardingLayout';

const Router = () => {
  const { authState } = useContext(AuthContext);
  return (
    <Routes>
      <Route>
        {signupRoutes.map((route, i) => {
          return (
            <Route
              key={`signup-route-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <route.element />
                </Suspense>
              }
            />
          );
        })}
      </Route>

      <Route element={<ProtectedOnboardingLayout />}>
        {onboardingRoutes.map((route, i) => {
          return (
            <Route
              key={`signup-route-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <route.element />
                </Suspense>
              }
            />
          );
        })}
      </Route>

      {/** Public Routes **/}
      <Route element={<PublicLayout />}>
        {publicRoutes
          .filter(r => !r.marketplace)
          .map((route, i) => {
            return (
              <Route
                key={`public-${i}`}
                path={route.path}
                element={
                  <Suspense fallback={<Loader />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
        {forbiddenRoutes.map((route, i) => {
          return (
            <Route
              key={`error-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <route.element />
                </Suspense>
              }
            />
          );
        })}
      </Route>

      {/** Protected Routes **/}
      <Route path="/" element={<ProtectedLayout />}>
        {protectedRoutes
          .filter(r => !r.marketplace)
          .map((route, i) => {
            return (
              <Route
                key={`protected-${i}`}
                path={route.path}
                element={
                  <Suspense fallback={<Loader />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
      </Route>

      {/** Admin Routes **/}
      <Route path="/" element={<ProtectedAdminLayout />}>
        {adminRoutes.map((route, i) => {
          return (
            <Route
              key={`admin-protected-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <route.element />
                </Suspense>
              }
            />
          );
        })}
      </Route>

      {/** Enterprise Routes **/}
      <Route path="/" element={<ProtectedEnterpriseLayout />}>
        {enterpriseRoutes.map((route, i) => {
          return (
            <Route
              key={`enterprise-protected-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  <route.element />
                </Suspense>
              }
            />
          );
        })}
      </Route>

      {/** External Routes **/}
      <Route path="/" element={<ExternalLayout />}>
        {externalRoutes.map((route, i) => {
          const role = route.roles;
          let isAllowed;
          if (!role?.length || !authState?.user) {
            isAllowed = true;
          } else {
            isAllowed =
              (role || []).includes(ROLES.EXTERNAL) &&
              authState?.user?.type?.includes('external');
          }
          return (
            <Route
              key={`protected-${i}`}
              path={route.path}
              element={
                <Suspense fallback={<Loader />}>
                  {isAllowed ? <route.element /> : <Navigate to="/forbidden" />}
                </Suspense>
              }
            />
          );
        })}
      </Route>

      <Route element={<ShopLayout />}>
        {publicRoutes
          .filter(r => r.marketplace)
          .map((route, i) => {
            return (
              <Route
                key={`protected-${i}`}
                path={route.path}
                element={
                  <Suspense fallback={<Loader />}>
                    <route.element />
                  </Suspense>
                }
              />
            );
          })}
      </Route>
      {/* default */}
      <Route
        key={`default`}
        path={'*'}
        element={
          <Suspense fallback={<Loader />}>
            <Navigate to="/forbidden" />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
