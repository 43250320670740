import React from 'react';
import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      /* Custom Loader */
      @font-face {
        font-family: 'Affogato-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/telup/fonts/Affogato-Regular.eot?#iefix') format('embedded-opentype'),
          url('/telup/fonts/Affogato-Regular.woff') format('woff'),
          url('/telup/fonts/Affogato-Regular.woff2') format('woff2'),
          url('/telup/fonts/Affogato-Regular.ttf')  format('truetype'),
          url('/telup/fonts/Affogato-Regular.svg') format('svg');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Affogato-Medium';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/telup/fonts/Affogato-Medium.eot?#iefix') format('embedded-opentype'),
          url('/telup/fonts/Affogato-Medium.woff') format('woff'),
          url('/telup/fonts/Affogato-Medium.woff2') format('woff2'),
          url('/telup/fonts/Affogato-Medium.ttf')  format('truetype'),
          url('/telup/fonts/Affogato-Medium.svg') format('svg');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Affogato-Bold';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/telup/fonts/Affogato-Bold.eot?#iefix') format('embedded-opentype'),
          url('/telup/fonts/Affogato-Bold.woff') format('woff'),
          url('/telup/fonts/Affogato-Bold.woff2') format('woff2'),
          url('/telup/fonts/Affogato-Bold.ttf')  format('truetype'),
          url('/telup/fonts/Affogato-Bold.svg') format('svg');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `}
  />
)
