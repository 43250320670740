
import { emptySplitApi } from './emptySplitApi';

export const SHOP_API_REDUCER_KEY = 'shopApi';

export const shopApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query({
      query: () => ({
        url: 'v1/shop/products',
      }),
    }),
    getProductById: builder.query({
      query: id => ({
        url: `v1/shop/products/${id}`,
      }),
    }),
    getPlans: builder.query({
      query: () => ({
        url: 'v1/shop/plans',
      }),
    }),
    getWorkflows: builder.query({
      query: params => ({
        url: 'v1/shop/workflows',
        params,
      }),
    }),
    getWorkflow: builder.query({
      query: id => ({
        url: `v1/shop/workflows/${id}`,
      }),
    }),
    getRelatedWorkflow: builder.query({
      query: ({id, params}) => ({
        url: `v1/shop/workflows/${id}/related-workflows`,
        params
      }),
    }),
    getPurchasedWorkflow: builder.query({
      query: params => ({
        url: `v1/shop/workflows/purchased`,
        params
      }),
      providesTags: ['Workflows'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductByIdQuery,
  useGetProductsQuery,
  useGetPlansQuery,
  useGetWorkflowsQuery,
  useGetWorkflowQuery,
  useGetRelatedWorkflowQuery,
  useLazyGetWorkflowsQuery,
  useGetPurchasedWorkflowQuery
} = shopApi;
