import React, { useEffect } from 'react';
import { ChakraProvider, Text } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Fonts } from './theme/fonts';
import theme from './theme/theme';
import Router from './Router';
import { store } from './redux/store';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ToursContext } from './providers/tours';
import AuthProvider from './providers/AuthProvider';
import { pdfjs } from 'react-pdf';
import ModalMessages from './ModalMessages';
import ErrorBoundary from './ErrorBoundary';

pdfjs.GlobalWorkerOptions.workerSrc = '/telup/pdf.worker.min.js';

// store.subscribe(() => {
//   // persist your state
//   try {
//     const state = store.getState();
//     if (state?.auth) {
//       const prev = localStorage.getItem('authState')
//         ? JSON.parse(localStorage.getItem('authState'))
//         : null;
//       const { user, defaultProfileRole, ...other } = state.auth;
//       // we are exceeding the storage quota, save only name email and it's done until we update other values by using an api call in redux
//       const newAuth = {
//         ...other,
//         defaultProfileRole,
//         user: {
//           country: user?.country,
//           created: user?.created,
//           type: user?.type,
//           email: user?.email,
//           emailValidation: user?.emailValidation,
//           first_name: user?.first_name,
//           hourlyRate: user?.hourlyRate,
//           city: user?.city,
//           company_name: user?.company_name,
//           approvedExpert: user?.approvedExpert,
//           name: user?.name,
//           last_name: user?.last_name,
//           onboarded: user?.onboarded,
//           ubimaxId: user?.ubimaxId,
//           userId: user?.userId,
//           validated: user?.validated,
//           parent: { name: user?.parent?.name || '', type: user?.parent?.type },
//           subscription: {
//             product: { name: user?.subscription?.product?.name },
//           },
//           _id: user?._id,
//         },
//       };
//       localStorage.setItem('authState', JSON.stringify(newAuth));
//     }
//   } catch (error) {
//     console.error(error);
//   }
// });

function App() {
  return (
    <ErrorBoundary
      fallback={<Text>Unexpected error. Refresh the browser</Text>}
    >
      <Provider store={store}>
        <BrowserRouter basename="/telup">
          <AuthProvider>
            <ChakraProvider theme={theme}>
              <ToursContext>
                <Fonts />
                <Router />
                <ModalMessages />
              </ToursContext>
            </ChakraProvider>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
