import { emptySplitApi } from './emptySplitApi';

export const ACCOUNT_API_REDUCER_KEY = 'enterpriseAccountApi';

export const accountApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getUsersFromEnterprise: builder.query({
      query: enterpriseId => {
        return {
          url: `v1/enterprise/${enterpriseId}/account/users`,
          method: 'GET',
        };
      },
      providesTags: ['EnterpriseUsers'],
    }),
    createNewUserFromEnterprise: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['EnterpriseUsers', 'Teams'],
    }),
    notifyNewUserSignedUpAndSignedIn: builder.query({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/signed-up`,
          method: 'GET',
          body,
        };
      },
    }),
    addUserToEnterprise: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['EnterpriseUsers', 'Teams'],
    }),
    addUserToLocation: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}/location/${body.locationId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['EnterpriseLocation'],
    }),
    deleteUserFromLocation: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}/location/${body.locationId}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['EnterpriseLocation'],
    }),
    deleteUserFromEnterprise: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['EnterpriseUsers'],
    }),
    addUserToTeam: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}/team/${body.teamId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Teams'],
    }),
    removeUserFromTeam: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}/team/${body.teamId}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Teams'],
    }),
    createEnterpriseUser: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users`,
          method: 'POST',
          body,
        };
      },
    }),
    updateEnterpriseUser: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    getUserFromEnterpriseById: builder.query({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/users/${body.userId}`,
          method: 'GET',
        };
      },
    }),
    updateEnterprisePrivacy: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/privacy`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Teams', 'UserSession'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUsersFromEnterpriseQuery,
  useAddUserToEnterpriseMutation,
  useAddUserToLocationMutation,
  useCreateNewUserFromEnterpriseMutation,
  useDeleteUserFromLocationMutation,
  useDeleteUserFromEnterpriseMutation,
  useAddUserToTeamMutation,
  useRemoveUserFromTeamMutation,
  useCreateEnterpriseUserMutation,
  useUpdateEnterpriseUserMutation,
  useLazyGetUserFromEnterpriseByIdQuery,
  useLazyNotifyNewUserSignedUpAndSignedInQuery,
  useUpdateEnterprisePrivacyMutation,
} = accountApi;
