
import { emptySplitApi } from './emptySplitApi';

export const ACCOUNT_API_REDUCER_KEY = 'accountApi';

export const accountApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query({
      query: params => ({
        url: 'v1/account/user',
        params,
      }),
      providesTags: ['Experts'],
    }),
    getUserById: builder.query({
      query: id => ({
        url: `v1/account/user/${id}`,
      }),
      providesTags: ['Users'],
    }),
    deleteUserById: builder.mutation({
      query: body => {
        return {
          url: `v1/account/user/${body.id}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Users']
    }),
    getPublicUserById: builder.query({
      query: id => ({
        url: `v1/account/user/public/${id}`,
      }),
      providesTags: ['Users'],
    }),
    updateUser: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/user',
          method: 'PUT',
          body,
        };
      },
    }),
    updateUserById: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `v1/account/user/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Users']
    }),
    getTags: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/tag`,
        params,
      }),
      providesTags: ['Tags'],
    }),
    addTag: builder.mutation({
      query: ({ id, body, enterpriseId }) => {
        return {
          url: `v1/enterprise/${enterpriseId}/account/tag/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    createTag: builder.mutation({
      query: ({ body, enterpriseId }) => {
        return {
          url: `v1/enterprise/${enterpriseId}/account/tag`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Tags'],
    }),
    detachTag: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/tag/${body.id}`,
          method: 'DELETE',
        };
      },
    }),
    getOfficehours: builder.query({
      query: params => ({
        url: `v1/enterprise/${params.enterpriseId}/tag`,
        params,
      }),
      providesTags: ['OfficeHours'],
    }),
    updateUserTag: builder.mutation({
      query: ({ id, body, enterpriseId }) => {
        return {
          url: `v1/enterprise/${enterpriseId}/account/user/skill/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Tags'],
    }),
    createNewUserTag: builder.mutation({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/skill`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Tags'],
    }),
    updateOfficehours: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/officehours',
          method: 'PUT',
          body,
        };
      },
    }),
    registerAsExpertFromTech: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/expert-request',
          method: 'POST',
          body,
        };
      },
    }),
    getReferralLinkCode: builder.mutation({
      query: _ => {
        return {
          url: 'v1/account/referral-link-code',
          method: 'GET',
        };
      },
      invalidatesTags: ['Experts'],
    }),
    expertRequests: builder.query({
      query: params => {
        return {
          url: 'v1/account/expert-request',
          method: 'GET',
          params,
        };
      },
      providesTags: ['Expert-Requests'],
    }),
    approveExpertRequest: builder.mutation({
      query: params => {
        return {
          url: `v1/account/expert-request/${params.id}/approve`,
          method: 'PUT',
          params,
        };
      },
      invalidatesTags: ['Expert-Requests'],
    }),
    assignUserToExpertRequest: builder.mutation({
      query: params => {
        return {
          url: `v1/account/expert-request/${params.id}/assign/${params.toUser}`,
          method: 'PUT',
          params,
        };
      },
      invalidatesTags: ['Expert-Requests'],
    }),
    requestChangesExpertRequest: builder.mutation({
      query: body => {
        return {
          url: `v1/account/expert-request/${body.id}/admin-request-changes`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Expert-Requests'],
    }),
    addInternalNoteExpertRequest: builder.mutation({
      query: body => {
        return {
          url: `v1/account/expert-request/${body.id}/internal-note`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Expert-Requests'],
    }),
    deleteExpertRequestFile: builder.mutation({
      query: body => {
        return {
          url: `v1/account/expert-request/${body.id}/file/${body.fileName}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Expert-Requests', 'Experts'],
    }),
    getMyReferrals: builder.query({
      query: body => {
        return {
          url: `v1/account/my-referrals`,
          method: 'GET',
        };
      },
    }),
    getMyCoupons: builder.query({
      query: body => {
        return {
          url: `v1/account/my-coupons`,
          method: 'GET',
        };
      },
    }),
    getApkLink: builder.query({
      query: body => {
        return {
          url: `v1/enterprise/${body.enterpriseId}/account/apk`,
          method: 'GET',
        };
      },
    }),
    getCoupon: builder.query({
      query: ({ code }) => {
        return {
          url: `v1/account/coupon/${code}`,
          method: 'GET',
        };
      },
    }),
    createCustomCalendarEvent: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/calendar/custom',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Experts'],
    }),
    editCustomCalendarEvent: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `v1/account/calendar/custom/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Experts'],
    }),
    deleteCustomCalendarEvent: builder.mutation({
      query: ({ id, ...body }) => {
        return {
          url: `v1/account/calendar/custom/${id}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['Experts'],
    }),
    refreshOutlookCalendarEvent: builder.mutation({
      query: () => {
        return {
          url: `v1/account/calendar/outlook-sync`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Experts'], // reload user
    }),
    refreshGoogleCalendarEvent: builder.mutation({
      query: () => {
        return {
          url: `v1/account/calendar/google-sync`,
          method: 'GET',
        };
      },
      invalidatesTags: ['Experts'], // reload user
    }),
    sendPasswordResetEmail: builder.mutation({
      query: params => {
        return {
          url: 'v1/account/forgot-password',
          method: 'GET',
          params,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: body => {
        return {
          url: `v1/account/forgot-password`,
          method: 'POST',
          body,
        };
      },
    }),
    resendValidateEmail: builder.query({
      query: body => {
        return {
          url: `v1/account/user/re-send-validate`,
          method: 'POST',
          body,
        };
      },
    }),
    resendValidateEmailUserId: builder.query({
      query: body => {
        return {
          url: `v1/account/user/re-send-validate/${body?.id}`,
          method: 'POST',
          body,
        };
      },
    }),
    getUsers: builder.query({
      query: params => {
        return {
          url: 'v1/account/users',
          method: 'GET',
          params,
        };
      },
      providesTags: ['Users'],
    }),
    getEnterprises: builder.query({
      query: params => {
        return {
          url: 'v1/account/enterprises',
          method: 'GET',
          params,
        };
      },
      providesTags: ['Enterprises']
    }),
    createNewAdminEnterprise: builder.mutation({
      query: body => {
        return {
          url: 'v1/account/enterprises',
          method: 'POST',
          body
        };
      },
      invalidatesTags: ['Enterprises']
    }),
    updateAdminEnterprise: builder.mutation({
      query: body => {
        return {
          url: `v1/account/enterprises/${body.id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['Enterprises']
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetUserQuery,
  useGetUserQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetPublicUserByIdQuery,
  useGetTagsQuery,
  useUpdateUserMutation,
  useAddTagMutation,
  useUpdateOfficehoursMutation,
  useCreateTagMutation,
  useDetachTagMutation,
  useRegisterAsExpertFromTechMutation,
  useGetReferralLinkCodeMutation,
  useExpertRequestsQuery,
  useLazyExpertRequestsQuery,
  useApproveExpertRequestMutation,
  useAssignUserToExpertRequestMutation,
  useAddInternalNoteExpertRequestMutation,
  useRequestChangesExpertRequestMutation,
  useDeleteExpertRequestFileMutation,
  useLazyGetMyReferralsQuery,
  useLazyGetMyCouponsQuery,
  useLazyGetCouponQuery,
  useCreateCustomCalendarEventMutation,
  useEditCustomCalendarEventMutation,
  useDeleteCustomCalendarEventMutation,
  useRefreshOutlookCalendarEventMutation,
  useRefreshGoogleCalendarEventMutation,
  useResetPasswordMutation,
  useSendPasswordResetEmailMutation,
  useUpdateUserTagMutation,
  useCreateNewUserTagMutation,
  useLazyResendValidateEmailQuery,
  useLazyResendValidateEmailUserIdQuery,
  useLazyGetApkLinkQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useLazyGetEnterprisesQuery,
  useGetEnterprisesQuery,
  useCreateNewAdminEnterpriseMutation,
  useUpdateAdminEnterpriseMutation,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation
} = accountApi;
