import { createSlice } from '@reduxjs/toolkit';

export const FULL_MODAL_NOTIFICATION_VALUES = [
  'showMessage3a',
  'showMessage3b',
  'showMessage3c',
  // 'showIncomingCallNotification',
  'validateYourEmailModalNotification',
  'welcomeModalNotification',
  // 'showIncomingAppointmentCallNotification' // this is not a full modal. It's just a right popup
];

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    showMessage3a: false,
    showMessage3b: false,
    showMessage3c: false,
    // showIncomingCallNotification: false,
    incomingCallNotification: [],
    showIncomingAppointmentCallNotification: false,
    validateYourEmailModalNotification: null,
    welcomeModalNotification: null,
  },
  reducers: {
    setShowMessage3a: (state, action) => {
      state.showMessage3a = action.payload;
    },
    setShowMessage3b: (state, action) => {
      state.showMessage3b = action.payload;
    },
    setShowMessage3c: (state, action) => {
      state.showMessage3c = action.payload;
    },
    setIncomingCallNotification: (state, action) => {
      state.incomingCallNotification = action.payload;
    },
    setAddIncomingCallNotification: (state, action) => {
      state.incomingCallNotification.push(action.payload);
    },
    setDeleteIncomingCallNotification: (state, action) => {
      state.incomingCallNotification = state.incomingCallNotification.filter(
        n => n.callId !== action.payload.callId
      );
    },
    setShowIncomingAppointmentCallNotification: (state, action) => {
      state.showIncomingAppointmentCallNotification = action.payload;
    },
    setValidateYourEmailModalNotification: (state, action) => {
      state.validateYourEmailModalNotification = action.payload;
    },
    setWelcomeModalNotification: (state, action) => {
      state.welcomeModalNotification = action.payload;
    },
  },
});

export const {
  setShowMessage3a,
  setShowMessage3b,
  setShowMessage3c,
  // setShowIncomingCallNotification,
  setValidateYourEmailModalNotification,
  setShowIncomingAppointmentCallNotification,
  setWelcomeModalNotification,
  setIncomingCallNotification,
  setAddIncomingCallNotification,
  setDeleteIncomingCallNotification
} = notificationSlice.actions;

export default notificationSlice.reducer;
