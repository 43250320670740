import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Heading,
  Flex,
  Text,
  VStack,
  HStack,
  Button,
  Box,
  Image,
  Spinner,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';
import _ from 'lodash';
import CurrencyFormat from 'react-currency-format';

import { AuthContext } from '../providers/AuthProvider';
import {
  useRemoveCartItemMutation,
  useUpdateCartMutation,
} from '../redux/api/cartApi';
import {
  useGetProductsQuery,
  useGetPlansQuery,
  useGetWorkflowsQuery,
} from '../redux/api/shopApi';
import ItemQuantityInput from './ItemQuantityInput';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCloseCircle } from 'react-icons/ai';
import { setShow } from '../redux/slices/cartSlice';

const Cart = () => {
  const navigate = useNavigate();
  const { show: showCart } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const { cartState, isCartLoading } = useContext(AuthContext);
  const { data: products = [], isFetching: isProductsFetching } =
    useGetProductsQuery();
  const { data: plans = [], isFetching: isPlansFetching } = useGetPlansQuery();
  const { data: workflows = [], isFetching: isWorkflowsFetching } =
    useGetWorkflowsQuery();
  const [removeCartItem, { isLoading: cartItemRemoving }] =
    useRemoveCartItemMutation();
  const [updateCartItem, { isLoading: cartItemUpdating }] =
    useUpdateCartMutation();

  const handleCartItemUpdate = async (item, qty) => {
    const body = {
      quantity: qty,
    };
    await updateCartItem({ cartId: cartState?._id, id: item?._id, body });
  };

  const getProdImage = useCallback(
    item => {
      if (item?.product_type === 'product') {
        const _item = _.find(products, { _id: item?.product });
        let prodImage = _item?.images && _item?.images[0];
        if (prodImage && !prodImage.includes('http')) {
          prodImage = `${process.env.REACT_APP_API_HOST}/api/images/${prodImage}`;
        }
        return prodImage;
      } else if (item?.product_type === 'plan') {
        const _item = _.find(plans, { _id: item?.product });
        return _item?.image;
      } else if (item?.product_type === 'workflow') {
        const _item = _.find(workflows, { _id: item?.product });
        return `${process.env.REACT_APP_API_HOST}/api/${_item?.workflowPicturePath}`;
      }
      //REACT_APP_API_HOST
    },
    [products, plans, workflows]
  );

  return (
    <Flex
      w="100%"
      h="full"
      py={[0, 5, 5]}
      direction="column"
      justifyContent="space-between"
    >
      <VStack
        w="full"
        h={'calc(100% - 145px)'}
        alignItems="flex-start"
        px={2}
        spacing={2}
        py={5}
        borderTop={{ base: '2px solid #DFDFDF', lg: 0 }}
      >
        <Heading
          w="full"
          as="h2"
          fontFamily="body"
          fontWeight="normal"
          fontSize="28px"
        >
          <Flex justifyContent={'space-between'}>
            <Box>Shopping Cart({cartState?.totalQuantity})</Box>
            <Button
              variant={'link'}
              onClick={() => dispatch(setShow(!showCart))}
            >
              <AiFillCloseCircle />
            </Button>
          </Flex>
        </Heading>
        {isCartLoading && <Spinner alignSelf="center" />}
        <VStack
          w="full"
          alignItems="flex-start"
          // maxH="calc(100vh - 130px)"
          maxH="100%"
          overflowY="auto"
        >
          {cartState?.lineItems?.map(item => {
            return (
              <VStack
                w="full"
                key={item._id}
                borderBottom="2px solid #DFDFDF"
                pt={5}
              >
                <HStack w="full">
                  <Image
                    fallbackSrc="https://via.placeholder.com/150x120/FBE2CF/000000/?text=ARExperts"
                    src={getProdImage(item)}
                    maxW={100}
                    objectFit="cover"
                  />
                  <VStack alignItems="flex-start">
                    <Heading
                      as="h5"
                      fontWeight="normal"
                      fontFamily="label"
                      fontSize={18}
                    >
                      {item.title}
                    </Heading>
                    <Text fontSize={16}>
                      <CurrencyFormat
                        value={item.price.toFixed(2) || 0.0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Text>
                  </VStack>
                </HStack>
                <HStack w="full" pb={5}>
                  <ItemQuantityInput
                    currentValue={item.quantity}
                    onIncrement={() =>
                      handleCartItemUpdate(item, item.quantity + 1)
                    }
                    onDecrement={() =>
                      handleCartItemUpdate(item, item.quantity - 1)
                    }
                    disabled={
                      cartItemUpdating ||
                      ['plan', 'workflow'].includes(item?.product_type)
                    }
                  />
                  <Button
                    variant="ghost"
                    size="sm"
                    colorScheme="red"
                    onClick={async () =>
                      await removeCartItem({
                        cartId: cartState?._id,
                        id: item._id,
                      })
                    }
                    disabled={cartItemRemoving}
                  >
                    Remove
                  </Button>
                </HStack>
              </VStack>
            );
          })}
        </VStack>
      </VStack>
      <VStack borderTop="2px solid #DFDFDF">
        <HStack
          w="full"
          justifyContent="space-between"
          p={5}
          borderBottom="2px solid #DFDFDF"
        >
          <Text fontSize="18px">Sub Total:</Text>
          <Text fontFamily="heading" fontSize="18px">
            <CurrencyFormat
              value={cartState?.total?.toFixed(2) || 0.0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Text>
        </HStack>
        <Box textAlign="center" pt={5} w="100%" px={5}>
          <Button
            variant="primary"
            w="100%"
            disabled={
              isCartLoading ||
              cartItemRemoving ||
              cartItemUpdating ||
              cartState?.total === 0
            }
            isLoading={isCartLoading}
            onClick={() => navigate('/marketplace/checkout')}
          >
            Proceed to Checkout
          </Button>
        </Box>
      </VStack>
    </Flex>
    // <Drawer isOpen={showCart} placement="right" size={'sm'}>
    //   <DrawerOverlay />
    //   <DrawerContent>
    //     <DrawerCloseButton />
    //     <DrawerBody>

    //     </DrawerBody>
    //   </DrawerContent>
    // </Drawer>
  );
};

export default Cart;
