import { Container } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '../../providers/AuthProvider';
import Navbar from './Navbar';
import Footer from './Footer';
import { APPROVED_EXPERT, ACCOUNT_TYPE_VALUES } from '../../utils';
import ModalMessages from '../../ModalMessages';
import { useLazyNotifyNewUserSignedUpAndSignedInQuery } from '../../redux/api/enterpriseAccountApi';

const ProtectedLayout = ({ history }) => {
  const { isAuthenticated, isSessionLoading, enterpriseId, authState } =
    useContext(AuthContext);
  const [notifyNewUserSignedUpAndSignedIn] =
    useLazyNotifyNewUserSignedUpAndSignedInQuery();
  const location = useLocation();
  const isChatPage = location.pathname.includes('expert-ai/chat');
  // case for when child users of unsubscribed enterprise
  // if has parent but type enterprise was removed
  const enterpriseUserUnsubscribed =
    authState.user?.parent?.name &&
    authState.user?.parent?.type &&
    !(authState.user?.parent?.type || []).includes(
      ACCOUNT_TYPE_VALUES.ENTERPRISE
    ) &&
    !(authState.user?.parent?.type || []).includes(
      ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE
    );
  const enterpriseUserSubscribed =
    (authState.user?.parent?.name &&
      authState.user?.parent?.type &&
      (authState.user?.parent?.type || []).includes(
        ACCOUNT_TYPE_VALUES.ENTERPRISE
      )) ||
    (authState.user?.parent?.type || []).includes(
      ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE
    );

  // check if comes from signup page if so do api call that the user is done and check if completed skills & working hours and send the emails...
  useEffect(() => {
    if (location.state?.from === 'signup') {
      notifyNewUserSignedUpAndSignedIn({ enterpriseId });
    }
  }, [location.state?.from]);

  if (
    !isSessionLoading &&
    enterpriseUserUnsubscribed &&
    !location.pathname.includes('unsubscribed-enterprise-warning')
  ) {
    return <Navigate to="unsubscribed-enterprise-warning" />;
  }

  // if parent subscribed redirect to dashboard
  if (
    !isSessionLoading &&
    enterpriseUserSubscribed &&
    location.pathname.includes('unsubscribed-enterprise-warning')
  )
    return <Navigate to="/dashboard" />;

  if (!isAuthenticated && !isSessionLoading && !authState?.user?._id)
    return <Navigate to="/login" />;

  // validate email landing page
  // if (
  //   !authState?.user?.validated &&
  //   !isSessionLoading &&
  //   !location.pathname.includes('validate-email')
  // )
  //   return <Navigate to="/validate-email" />;

  // user is valid but I'm on validate email page => go to dashboard
  if (
    // authState?.user?.validated &&
    !isSessionLoading &&
    ((authState?.defaultProfileRole === 'expert' &&
      authState?.user?.approvedExpert === APPROVED_EXPERT) ||
      authState?.defaultProfileRole === 'tech') &&
    location.pathname.includes('validate-email')
  )
    return <Navigate to="/dashboard" />;

  // remove expert not approved page. Enable navigation but show an alert that he won't be appear in tech searches
  // Technicians won’t be able to book your time until you add at least one skill and working hour
  // if (
  //   // authState?.user?.validated &&
  //   // !authState?.user?.subscription?.product?.name &&
  //   !location.pathname.includes('expert-not-approved') &&
  //   authState?.defaultProfileRole === 'expert' &&
  //   authState?.user?.approvedExpert !== APPROVED_EXPERT
  // )
  //   return <Navigate to="/expert-not-approved" />;

  // force choose plan ONLY if enterprise
  if (
    // authState?.user?.validated &&
    !location.pathname.includes('subscription-plans') &&
    isAuthenticated &&
    !authState?.user?.subscription?.product?.name &&
    !isSessionLoading &&
    [
      ACCOUNT_TYPE_VALUES.ENTERPRISE,
      ACCOUNT_TYPE_VALUES.UNLIMITED_ENTERPRISE,
    ].includes(authState?.defaultProfileRole) &&
    !authState?.user?.parent // add this for the case when we have admin enterprise users. These users will have a parent and enterprise type.
    // &&
    // ((authState?.defaultProfileRole === 'expert' &&
    //   authState?.user?.approvedExpert === APPROVED_EXPERT) ||
    //   authState?.defaultProfileRole === 'tech')
  )
    return <Navigate to="/subscription-plans" />;

  // if (
  //   (location.pathname.includes('subscription-plans') ||
  //     location.pathname.includes('expert-not-approved')) &&
  //   isAuthenticated &&
  //   !authState?.user?.subscription &&
  //   !authState?.user?.subscription?.product?.name &&
  //   (isSubscriptionSuccess) &&
  //   !isSessionLoading
  // )
  // // keep loading if succeeded but the user wasn't updated yet
  //   return;

  // // if subscription succeeded go to onboard section
  // if (
  //   (location.pathname.includes('subscription-plans') ||
  //     location.pathname.includes('expert-not-approved')) &&
  //   isAuthenticated &&
  //   authState?.user?.subscription &&
  //   authState?.user?.subscription?.product?.name &&
  //   (!isUpgrade || isSubscriptionSuccess) &&
  //   !isSessionLoading
  // )
  //   return <Navigate to="/onboard-call" />;

  return (
    <>
      <Navbar />
      <Container
        maxW="container.xl"
        minH={`calc(100vh - ${isChatPage ? 0 : 96}px - 105px)`}
      >
        {isAuthenticated && <Outlet context={{ isPrivate: true }} />}
      </Container>
      {/* <ModalMessages /> */}
      <Footer />
    </>
  );
};

export default ProtectedLayout;
